import { useState, useEffect, useRef } from "react";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { Range, getTrackBackground } from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 30;

const BasicVisibleLabelExample = ({
  setFieldValue,
  name,
  value,
  rtl,
  valuez,
}: any) => {
  useEffect(() => {
    setValues([value]);
  }, [value]);

  const [values, setValues] = useState<any>([value]);
  const rangeRef: any = useRef<any>();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {/* <label
        onClick={(e) => {
          rangeRef.current.thumbRefs[0].current.focus();
        }}
        id="unique_id"
        style={{
          flex: "auto",
          fontFamily: "sans-serif",
        }}
      >
        Visible accessibility label:
      </label> */}

      <Range
        labelledBy="unique_id"
        ref={rangeRef}
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
         rtl={rtl}
        onChange={(values) => {
          setValues(values);
          setFieldValue(name, values?.[0].toFixed(1));
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "30px",
              display: "flex",
              width: "100%",
            }}
          >
            {MIN}Km
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#181460", "#ccc"],
                  min: MIN,
                  max: MAX,
                  rtl,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
            {MAX}Km
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            key={props.key}
            className="d-flex justify-content-center align-items-center pre-box-sec01"
            style={{
              ...props.style,
              height: "25px",
              width: "7px",
              backgroundColor: "#6ecace",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: "#6ecace",
              }}
            />
          </div>
        )}
      />
      {/* <output style={{ marginTop: "30px" }} id="output">
        {values[0].toFixed(1)}
      </output> */}
    </div>
  );
};

export default BasicVisibleLabelExample;
