import { useQuery } from "react-query";
import { getCareGiverSingle } from "../../../Api/WebsiteApi";
import Loader from "../../../components/Loader/Loader";
import { calculateYearsOfExperience } from "../../../Utils/helpers/helpers";
import { useState } from "react";
import { FullCalendarIndex } from "../../../components/FullCander/FullViewCalander";
import ModalContainer from "../../../components/Modal/ModalContainer";
import moment from "moment";
import TopSection from "./SIngleCareGiver/TopSection";
import Overview from "./SIngleCareGiver/Overview";
import ShortBio from "./SIngleCareGiver/ShortBio";
import Availability from "./SIngleCareGiver/Avalibilty";
import Reviews from "./SIngleCareGiver/Reviews";
import TabIndex from "./SIngleCareGiver/Tabindex";

export default function SIngleCareGiver() {
  const [tab, setTab] = useState(1);
  const currentUrl = window.location.href;

  // Step 2: Create a URL object
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  const id = params.get("id");
  const { data: caregiver, isLoading: careGiversLoading }: any = useQuery(
    [`caregiver`],
    () => getCareGiverSingle(id),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );

  const [pen_modal, setopenmoadl] = useState(false);

  const data_path = caregiver?.user_details;
  const totalExperience = caregiver?.user_details?.work_experiences?.reduce(
    (total: any, experience: any) => {
      const { years, months } = calculateYearsOfExperience(
        experience?.startDate,
        experience?.endDate
      );

      // Add the years and months from the current experience to the total
      total.years += years;
      total.months += months;

      // If the total months exceed 12, convert them into years
      if (total.months >= 12) {
        total.years += Math.floor(total.months / 12);
        total.months = total.months % 12;
      }

      return total;
    },
    { years: 0, months: 0 } // Initial total as 0 years and 0 months
  );
  console.log(totalExperience, "totalExperience");

  const view_data = (value: any, map_data: any, namez: any) => {
    return (
      <div className="col-lg-9 col-md-8 mt-4">
        <h6 className="mt-0 mb-2">{value}</h6>
        <p className="m-0 mt-2">
          {map_data?.map((item: any) => {
            return (
              <>
                {" "}
                <a className="btn btn-grey-small mr-5 mt-2" href="#">
                  {item?.[namez]?.name}
                </a>
              </>
            );
          })}
        </p>
      </div>
    );
  };
  const view_dataExp = (map_data: any) => {
    return (
      <>
        <ul>
          {map_data?.map((item: any) => {
            return (
              <>
                <li>
                  {/* {item?.companyName}(
                  {calculateYearsOfExperience(
                    item?.startDate,
                    item?.endDate
                  )?.toFixed(2)
                  }
                  yrs ) in {item?.designation} */}
                  {item?.companyName} (
                  {calculateYearsOfExperience(item?.startDate, item?.endDate)
                    ?.months === 0
                    ? calculateYearsOfExperience(item?.startDate, item?.endDate)
                        ?.years +" Yrs"
                    : calculateYearsOfExperience(item?.startDate, item?.endDate)
                        ?.months+" Months"}
                  ) in {item?.designation}
                  <hr />
                  <p>{item?.description}</p>
                </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };
  const view_dataEdu = (map_data: any) => {
    return (
      <>
        <ul>
          {map_data?.map((item: any) => {
            return (
              <>
                <li>
                  {item?.courseName} from {item?.institutionName}
                  <hr />
                  <span>
                    {moment(item?.startDate).format("YYYY")}-
                    {moment(item?.endDate).format("YYYY")}
                  </span>
                  <p>{item?.description}</p>
                </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };
  return (
    <>
      <style>
        {`
          .text-description { max-width: max-content; }
          .sidebar-list-job ul li .sidebar-text-info span, li, p { font-family: 'Satoshi-Medium'; }
          .font-md { font-family: 'Satoshi-Bold'; }
          .view-care-givers .giver-view-info .badge { 
            font-size: 13px;
            font-weight: 800;
          }
            .fc .fc-view-harness {
            height: calc(100vh - -350px)!important;
            }
            .sticky-bar.stick {
            z-index: 0;
            }
        `}
      </style>
      {careGiversLoading && <Loader />}
      <div className="view-care-givers">
        <TopSection
          totalExperience={totalExperience}
          setTab={setTab}
          caregiver={caregiver}
          data_path={data_path}
        />

        <section className="section-box mt-25">
          <div className="container">
            <div className="row find-giver-row">
              <Overview
                totalExperience={totalExperience}
                setTab={setTab}
                caregiver={caregiver}
                data_path={data_path}
              />

              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="box-nav-tabs mt-0 mb-5">
                  <TabIndex setTab={setTab} tab={tab} />
                </div>
                <div className="content-single">
                  <div className="tab-content">
                    {tab === 1 && (
                      <>
                        <ShortBio
                          view_dataExp={view_dataExp}
                          data_path={data_path}
                          view_data={view_data}
                          view_dataEdu={view_dataEdu}
                          setopenmoadl={setopenmoadl}
                        />
                      </>
                    )}

                    {tab === 2 && (
                      <>
                        <Availability data_path={data_path} />
                      </>
                    )}
                    {tab === 3 && (
                      <>
                        <Reviews />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Modal Popup Full Screen  */}
      <ModalContainer title="" isOpen={pen_modal} setIsopen={setopenmoadl}>
        Modal
      </ModalContainer>
    </>
  );
}
