import { api } from "./Api";

export async function getserviceTypeList() {
  const [, data]: any = await api.get(
    `/user/service_type?limit=29`,

    true
  );
  return data?.data;
}
export async function getserviceTypeListDropdwon() {
  const [, data]: any = await api.get(
    `/user/service_type?limit=29`,

    true
  );
  const ne_arry: any = [];
  data?.data?.data?.map((item: any) => {
    ne_arry?.push({
      value: item?.id,
      label: item?.name,
    });
  });
  return ne_arry;
}
export async function getState(state_input?: any) {
  const [, data]: any = await api.get(
    `/user/master/state?name=${state_input}`,

    true
  );
  const ne_arry: any = [];
  data?.data?.map((item: any) => {
    ne_arry?.push({
      value: item?.id,
      label: item?.name,
    });
  });
  return ne_arry;
}
export async function getserviceSingle(id: any) {
  const [, data]: any = await api.get(
    `/user/service_type/${id}`,

    true
  );
  return data?.data;
}
export async function getUserData() {
  const [, data]: any = await api.get(
    `/user/user_details`,

    true
  );
  return data?.data;
}
export async function getLoactions(loaction: any) {
  const [, data]: any = await api.get(
    `https://www.workforceaustralia.gov.au/api/v1/global/addresses?address=${loaction}&addressScope=Suburb&addressDataSource=GNAF&excludeLatLong=false`,

    true
  );
  return data?.data;
}

export async function getSupportData() {
  const [, data]: any = await api.get(
    `/user/master/support_for`,

    true
  );
  return data?.data;
}
export async function getPaymentData() {
  const [, data]: any = await api.get(
    `/user/master/payment_modes`,

    true
  );
  return data?.data;
}
export async function getServiceData() {
  const [, data]: any = await api.get(
    `/user/master/service_type`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getResidancyData() {
  const [, data]: any = await api.get(
    `/user/master/residency_status`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getProfileData() {
  const [, data]: any = await api.get(
    `/user/master/profile_types`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getLangData() {
  const [, data]: any = await api.get(
    `/user/master/languages`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getIntersetData() {
  const [, data]: any = await api.get(
    `/user/master/interests`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getcareTaskData() {
  const [, data]: any = await api.get(
    `/user/master/profile_types`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getWillingToWorkData() {
  const [, data]: any = await api.get(
    `/user/master/willing_to_work`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getExpAgedCareData() {
  const [, data]: any = await api.get(
    `/user/master/experience_with_aged_cares`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}
export async function getExpChildrenData() {
  const [, data]: any = await api.get(
    `/user/master/experience_with_child`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.name,
  }));

  return new_array;
}

export async function getLocationData(inputValue: any) {
  const [, data]: any = await api.get(
    `/user/master/locations?code=${inputValue}`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.locality,
  }));

  return new_array;
}
export async function getLocationData_(inputValue: any) {
  const [, data]: any = await api.get(
    `/user/master/locations?search=${inputValue}`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    id: item?.id,
    name: `${item?.locality} - ${item?.stateName} (Post code:${item?.postcode})`,
  }));

  return new_array;
}
export async function getLocations(inputValue: any) {
  const [, data]: any = await api.get(
    `/user/master/locations?search=${inputValue}`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: `${item?.locality} - ${item?.stateName} (Post code:${item?.postcode})`,
  }));

  return new_array;
}
export async function getServiceProvides() {
  const [, data]: any = await api.get(
    `/user/master/service_type`,

    true
  );

  return data?.data;
}
export async function getServiceProvidesList(
  service_type_id: any,
  suburb_id: any,
  radius: any,
  gnder?: any,
  lang?: any,
  state?: any,
  post_code?: any
) {
  const [, data]: any = await api.get(
    `/user/service_providers?suburb_id=${suburb_id}&radius=${radius}&service_type_id=${service_type_id}&gender=${gnder}&lang=${lang}&state=${state}&post_code=${post_code}`,

    true
  );

  return data;
}
export async function getCareGiverSingle(id: any) {
  const [, data]: any = await api.get(
    `/user/caregiver_details/${id}`,

    true
  );
  return data?.data?.[0];
}
export async function getFAQList() {
  const [, data]: any = await api.get(
    `/user/faq`,

    true
  );
  return data?.data;
}
export async function getAustState() {
  const [, data]: any = await api.get(
    `/user/master/aus_states`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: `${item?.stateName} - (${item?.stateCode})`,
  }));

  return new_array;
}
export async function getSubrub(state: any) {
  const [, data]: any = await api.get(
    `/user/master/state/suburb/${state?.value?state?.value:state}`,

    true
  );
  const new_array: any = data?.data?.map((item: any) => ({
    value: item?.id,
    label: `${item?.locality} - (${item?.postcode})`,
  }));

  return new_array;
}
