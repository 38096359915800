import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import InputSelectCommon from "../../../components/CommonSelect/InputSelect";
import {
  GENDER,
  SUPPORT_FOR_YOU,
} from "../../../Utils/Constants/select_options";
import {
  addModalInitialValuesHandlerStep1,
  ProfileSubmitHandlerStep1,
} from "../ProfileMethods/ProfileSubmitHandlers";
import { initialValuesStep1 } from "../ProfileMethods/profileInitialValues";
import { validationSchemaStep1 } from "../ProfileMethods/ProfileValidation";
import {
  getAustState,
  getLocationData,
  getLocationData_,
  getSubrub,
  getSupportData,
} from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import axios from "axios";
import SuburbAutoComplete from "./components/SuburbAutoComplete";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import Autocomplete from "./components/AutoComplete";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DatePicker from "react-datepicker";
import SelectComponent from "../../../components/ReactSelect/ReactSelect";

const Step1 = ({ setStep, Userdata, ListRefetch, UserLoading_data }: any) => {
  const navigate = useNavigate();
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep1);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep1(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep1);
    }
  }, [Userdata, Userdata?.id]);

  const {
    data: Supprtdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`Supprtdata`], () => getSupportData(), {
    keepPreviousData: true,
  });
  const [inputValue, setInputValue] = useState(
    Userdata?.careseeker_details?.state?.id
      ? Userdata?.careseeker_details?.state?.id
      : []
  );

  const { data: state_aust } = useQuery([`state_aust`], () => getAustState(), {
    keepPreviousData: true,
    staleTime: 1000,
  });
  const { data: surub_data, isLoading: subloading } = useQuery(
    [`surub_data`, inputValue],
    () => (inputValue ? getSubrub(inputValue) : ""),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  console.log(inputValue?.length, "inputValue");

  return (
    <>
      {UserLoading_data ? (
        <Skeleton count={5} />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={addModalInitialValues}
          validationSchema={validationSchemaStep1}
          onSubmit={(values, actions) => {
            ProfileSubmitHandlerStep1(
              values,
              actions,
              setStep,
              navigate,
              ListRefetch
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
          }) => (
            <>
              {console.log(values, "asagshga")}
              <Form
                className="seeker-registration-form2 giver-register4"
                onSubmit={handleSubmit}
              >
                <div className="text-center mb-3 logo-div01">
                  <h3 className="block-subTitle text-center uss-text-info">
                    {/* <span className="uss-text-primary">Create</span> Account */}
                  </h3>
                </div>
                <FormGroup>
                  <Label>Support for </Label>
                  <InputSelectCommon
                    size={1}
                    data={Supprtdata}
                    name="suppot_for_you"
                    selectClass={`form-control col-12 ${`${
                      errors.suppot_for_you ? "is-invalid" : "is-valid"
                    }`}`}
                    // value={Supprtdata?.find(
                    //   (item: any) => item.name === values?.suppot_for_you
                    // )}
                  />
                  <ErrorMessage
                    name="suppot_for_you"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>First Name</Label>
                  <Field
                    type="text"
                    name="first_name"
                    className={`form-control ${`${
                      errors.first_name ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.first_name}
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Field
                    type="text"
                    name="last_name"
                    className={`form-control ${`${
                      errors.last_name ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.last_name}
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Mobile</Label>
                  <Field
                    type="text"
                    name="mobile"
                    className={`form-control ${`${
                      errors.mobile ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.mobile}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Email</Label>
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${`${
                      errors.email ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.email}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Date of Birth</Label>
                  {/* <Field
                    type="date"
                    name="dob"
                    className={`form-control ${`${
                      errors.dob ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.dob}
                  /> */}
                  <DatePicker
                    selected={values.dob ? new Date(values.dob) : null}
                    onChange={(date: any) => setFieldValue("dob", date)}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    placeholderText="DD/MM/YYYY"
                    className={`form-control ${
                      errors.dob ? "is-invalid" : "is-valid"
                    }`}
                    showIcon
                  />
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Gender</Label>
                  <Row>
                    {GENDER?.map((item: any, index: number) => {
                      return (
                        <div className="col-2" key={index}>
                          <Field
                            type="radio"
                            name="gender"
                            value={item.id}
                            // className={`form-check-input ${
                            //   errors.gender && touched.gender
                            //     ? "is-invalid"
                            //     : ""
                            // }`}
                            checked={values?.gender === item?.id?.toString()}
                          />
                          &nbsp;
                          <Label className="form-check-label">
                            {item.name}
                          </Label>
                        </div>
                      );
                    })}
                  </Row>

                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <Card>
                  <CardBody>
                    <FormGroup>
                      <Label>Address</Label>

                      <hr />
                      <FormGroup>
                        <Label>Street Number</Label>
                        <input
                          type="text"
                          name="street_number"
                          className={`form-control ${
                            errors.street_number ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.street_number}
                          onChange={handleChange}
                          // style={{ height: "150px" }}
                        />
                        {errors.street_number && (
                          <div className="text-danger">
                            {errors.street_number}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Address Line - 1</Label>
                        <input
                          type="text"
                          name="address_line_one"
                          className={`form-control ${
                            errors.address_line_one ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.address_line_one}
                          onChange={handleChange}
                          // style={{ height: "150px" }}
                        />
                        {errors.address_line_one && (
                          <div className="text-danger">
                            {errors.address_line_one}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Address Line - 2</Label>
                        <input
                          type="text"
                          name="address_line_two"
                          className={`form-control ${
                            errors.address_line_two ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.address_line_two}
                          onChange={handleChange}
                          // style={{ height: "150px" }}
                        />
                        {errors.address_line_two && (
                          <div className="text-danger">
                            {errors.address_line_two}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>State</Label>
                        <SelectComponent
                          loading={subloading}
                          options={state_aust}
                          value={state_aust?.find(
                            (option: any) => option.value === values.state
                          )}
                          onChange={(selectedOption: any) => {
                            setFieldValue("state", selectedOption?.value);

                            setInputValue(selectedOption?.value);
                          }}
                          isClearable={true}
                          error={errors?.state ? errors.state : ""}

                          // isMulti={true}
                        />
                        {errors.state && (
                          <div className="text-danger">{errors.state}</div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Suburb</Label>
                        <SelectComponent
                          loading={subloading}
                          options={surub_data ? surub_data : []}
                          value={
                            surub_data
                              ? surub_data?.find(
                                  (option: any) =>
                                    option.value === values?.suburb
                                )
                              : null
                          }
                          onChange={(selectedOption: any) => {
                            setFieldValue(
                              "suburb",
                              parseInt(selectedOption?.value)
                            );
                          }}
                          isClearable={true}
                          error={errors?.suburb ? errors.suburb : ""}
                        />
                        {errors?.suburb && (
                          <div className="text-danger">{errors?.suburb}</div>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </CardBody>
                </Card>

                <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    <span> Save & Next </span> <i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};
export default Step1;
