import { routeType } from "../../../Types";
import CreateProfileCareSeeker from "../../CareSeekerProfile/CreateProfile";
import CreateProfile from "../../CreateProfile/CreateProfile";
import AboutasIndex from "../../Public/Aboutus";
import ContactIndux from "../../Public/Contactus";
import FAQPage from "../../Public/Home/Containers/faq";
import PrivacyPolicyPage from "../../Public/Home/Containers/PrivacyPolicy";
import TermsConditionsPage from "../../Public/Home/Containers/TermsConditions";
import SearchOutput from "../../Public/FIndCareGiver/SearchOutput";
import HomeIndex from "../../Public/Home/Index";
import HowitIndex from "../../Public/HowItWork";
import CareGiverRegister from "../../Public/HowItWork/Containers/CareGiverRegister/CareGiverRegister";
import CareSeekerRegister from "../../Public/HowItWork/Containers/CareSeekerRegister/CareSeekerRegister";
import CommingSoon from "../../Public/PageUnderconstrution/CommingSoon";
import HowItWork from "../../Public/HowItWork/HowItWork";
import ServicesIndex from "../../Public/Services";
import ServiceDetiles from "../../Public/Services/Containers/ServiceDetiles";
import ServiceDetilesIndex from "../../Public/Services/ServiceDetilesIndex";
import SucccessPage from "../../SuccessPage/SuccessPage";
import SucccessPageCare from "../../SuccessPage/SuccessPageCareSeeker";
import SIngleCareGiver from "../../Public/FIndCareGiver/SIngleCareGiver";
import NewsIndex from "../../Public/News";
import ChatRoom from "../../Public/FIndCareGiver/SIngleCareGiver/Chat";
import WebSocketComponent from "../../Websoket/Websoket";

export const publicRoutes: routeType[] = [
  {
    path: "/",
    element: <HomeIndex />,
  },
  {
    path: "/about-us",
    element: <AboutasIndex />,
  },
  {
    path: "/services",
    element: <ServicesIndex />,
  },
  {
    path: "/register",
    element: <HowitIndex />,
  },
  {
    path: "/contact",
    element: <ContactIndux />,
  },
  {
    path: "/careseeker-create-account",
    element: <CareSeekerRegister />,
  },

  {
    path: "/caregiver-create-account",
    element: <CareGiverRegister />,
  },
  {
    path: "/success",
    element: <SucccessPage />,
  },
  {
    path: "/success-careseeker",
    element: <SucccessPageCare />,
  },
  {
    path: "/create-profile-caregiver",
    element: <CreateProfile />,
  },
  {
    path: "/create-profile-careseeker",
    element: <CreateProfileCareSeeker />,
  },

  {
    path: "/services-detailes",
    element: <ServiceDetilesIndex />,
  },
  {
    path: "/find/caregivers",
    element: <SearchOutput />,
  },
  {
    path: "/faq",
    element: <FAQPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-conditions",
    element: <TermsConditionsPage />,
  },
  {
    path: "/comming-soon",
    element: <CommingSoon />,
  },
  {
    path: "/how-it-work",
    element: <HowItWork />,
  },
  {
    path: "/care-giver-view",
    element: <SIngleCareGiver />,
  },
  {
    path: "/news",
    element: <NewsIndex />,
  },
  {
    path: "/chat",
    element: <ChatRoom />,
  },
  {
    path: "/socket",
    element: <WebSocketComponent/>,
  },
];
