import { useQuery } from "react-query";
import Subscribe from "../Home/Containers/Subscribe";
import ServicesBanner from "./Containers/banner";
import ServiceMainPage from "./Containers/ServicesMainPage";
import { getserviceTypeList } from "../../../Api/WebsiteApi";

export default function ServicesIndex() {
  const {
    data: dataList,
    error: dataError,
    isLoading: dataLoading,
    refetch: dataListRefetch,
  }: any = useQuery([`services`], () => getserviceTypeList(), {
    keepPreviousData: true,
    staleTime: 1000,
  });
  return (
    <>
      <main className="main">
        <ServicesBanner dataListcount={dataList?.data?.length} />
        <ServiceMainPage dataList={dataList} dataLoading={dataLoading} />
        <Subscribe />
      </main>
    </>
  );
}
