import { toast } from "react-toastify";
import { isNumeric, setValidationErrors } from "../../../Utils/helpers/helpers";
import { api } from "../../../Api/Api";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import Swal from "sweetalert2";
import moment from "moment";

export const ProfileSubmitHandlerStep1 = (
  values: any,
  actions: any,
  setStep: any,
  navigate: any,
  ListRefetch: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "1");

  // formData.append(
  //   "suburb",
  //   isNumeric(values?.suburb) ? values?.suburb : values?.sub_id
  // );
  formData.append("support_for", values?.suppot_for_you);
  formData.append("email", values?.email);
  formData.append("first_name", values?.first_name);
  formData.append("last_name", values?.last_name);
  formData.append("mobile", values?.mobile);
  formData.append("dob", moment(values?.dob).format("YYYY-MM-DD"));
  formData.append("gender", values?.gender);
  formData.append("street_number", values?.street_number);
  formData.append("address_line_one", values?.address_line_one);
  formData.append("address_line_two", values?.address_line_two);
  formData.append("state", values?.state);
  formData.append("suburb", values?.suburb);

  axios({
    method: "post",
    url: `${BaseUrl}/user/careseeker_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      console.log(response, "response");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");

        actions?.setSubmitting(true);
        // navigate("/create-profile-careseeker/2");
        ListRefetch();
        setStep(2);
      } else {
        // toast.success(response?.data?.message);
        actions?.setSubmitting(true);
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        Swal.fire({
          title: "Error",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
        // toast.error("Unexpected Error Occurred");
      }
    });
};
export const ProfileSubmitHandlerStep2 = (
  values: any,
  actions: any,
  setStep: any,
  navigate: any,
  ListRefetch: any
) => {
  console.log(values, "values");
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "2");
  // Append non-file fields
  formData.append("payment_mode", values.payment_mode);

  formData.append(
    "ndis_contact_number",
    values.contact_number_dis ? values.contact_number_dis : "test"
  );

  formData.append(
    "ndis_contact_name",
    values.contact_name_ndis ? values.contact_name_ndis : "test"
  );
  formData.append("ndis_id", values.ndis ? values.ndis : "test");
  formData.append(
    "plan_manager_name",
    values.plan_manager_name ? values.plan_manager_name : "test"
  );
  formData.append(
    "email_address_for_sending_invoice",
    values.email ? values.email : "test"
  );
  axios({
    method: "post",
    url: `${BaseUrl}/user/careseeker_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      console.log(response, "response");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        setStep(3);
        ListRefetch();
        // navigate("/create-profile-careseeker/3");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        // toast.error("Unexpected Error Occurred");
        Swal.fire({
          title: "Error",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
      }
    });
};
export const ProfileSubmitHandlerStep3 = (
  values: any,
  actions: any,
  setStep: any,
  navigate: any,
  ListRefetch: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "3");

  // Append simple fields

  formData.append("profile_title", values.care_request_title);
  formData.append("bio_description", values.summary);
  formData.append(
    "health_concerns_or_medical_conditions",
    values.health_status
  );

  axios({
    method: "post",
    url: `${BaseUrl}/user/careseeker_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        ListRefetch();
        setStep(4);
        // navigate("/success-careseeker?profile_completed=1");
        // navigate("/create-profile-careseeker/4");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        // toast.error("Unexpected Error Occurred");
        Swal.fire({
          title: "Error",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
      }
    });
};

export const ProfileSubmitHandler5 = (
  values: any,
  actions: any,
  navigate: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "5");

  formData.append("abn", values?.abn);
  formData.append("extra_charge_after_hours", values?.extra_charge_after_hours);
  formData.append("bank_name", values?.bank_name);
  formData.append("bank_branch_name", values?.bank_branch_name);
  formData.append("bank_account_number", values?.bank_account_number);

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        // setStep(2);
        navigate("/success?profile_completed=1");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        // toast.error("Unexpected Error Occurred");
      }
    });
};

// Step1 Values

export const addModalInitialValuesHandlerStep1 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  setAddModalInitialValues({
    first_name: UserData?.firstName,
    email: UserData?.email,
    mobile: UserData?.mobile,
    suppot_for_you: UserData?.careseeker_details?.supportFor?.id,
    suburb: UserData?.careseeker_details?.suburb?.id,
    dob: UserData?.careseeker_details?.dob,
    gender: UserData?.careseeker_details?.gender?.id?.toString(),

    last_name: UserData?.lastName,
    street_number: UserData?.careseeker_details?.streetNumber,
    address_line_one: UserData?.careseeker_details?.addressLineOne,
    address_line_two: UserData?.careseeker_details?.addressLineTwo,
    state: UserData?.careseeker_details?.state?.id,
  });
};

export const addModalInitialValuesHandlerStep2 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  console.log(UserData, "UserData");
  setAddModalInitialValues({
    payment_mode: UserData?.careseeker_details?.paymentMode?.id?.toString(),
    ndis:
      UserData?.careseeker_details?.ndisId === "test"
        ? ""
        : UserData?.careseeker_details?.ndisId,
    plan_manager_name:
      UserData?.careseeker_details?.planManagerName === "test"
        ? ""
        : UserData?.careseeker_details?.planManagerName,
    contact_name_ndis:
      UserData?.careseeker_details?.ndisContactName === "test"
        ? ""
        : UserData?.careseeker_details?.ndisContactName,
    contact_number_dis:
      UserData?.careseeker_details?.ndisContactNumber === "test"
        ? ""
        : UserData?.careseeker_details?.ndisContactNumber,
    email: UserData?.careseeker_details?.emailAddressForSendingInvoice,
  });
};
export const addModalInitialValuesHandlerStep3 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  console.log(UserData, "UserData");
  setAddModalInitialValues({
    care_request_title: UserData?.careseeker_details?.profileTitle,
    summary: UserData?.careseeker_details?.bioDescription,
    health_status:
      UserData?.careseeker_details?.healthConcernsOrMedicalConditions,
  });
};
