/* eslint-disable jsx-a11y/anchor-is-valid */

import { capitalizeFirstLetterOfEachWord } from "../../../../Utils/helpers/helpers";

export default function TopSection({
  data_path,
  totalExperience,
  caregiver,
  setTab,
}: any) {
  return (
    <>
      <section className="section-box-2">
        <div className="container">
          <div className="box-company-profile">
            <div className="row mt-10">
              <div className="col-lg-9 col-md-8">
                <div className="d-float-center giver-view-profile">
                  <div className="image-compay position-relative">
                    <img src={data_path?.profileImgThumbnail} alt="jobbox" />
                  </div>
                  <div className="giver-view-info">
                    <h5 className="f-18">
                      {caregiver?.firstName} {caregiver?.lastName}
                      <span className="badge bg-outline-success text-success">
                        $
                        {data_path?.user_details?.caregiver_charges?.[3]?.rate
                          ? data_path?.user_details?.caregiver_charges?.[3]
                              ?.rate
                          : 0}
                        /hour
                      </span>
                    </h5>
                    <div className="d-float-center giver-top-box-info my-1">
                      <p className="mt-0 font-md color-text-paragraph-2 mb-0">
                        {caregiver?.user_details?.work_experiences?.[0]
                          ?.designation && (
                          <>
                            {capitalizeFirstLetterOfEachWord(
                              caregiver?.user_details?.work_experiences?.[0]
                                ?.designation
                            )}{" "}
                            (
                            {totalExperience?.months === 0
                              ? totalExperience?.years?totalExperience?.years+" Yrs":"1 Month "
                              : totalExperience?.months + " Months "}
                            exp)
                          </>
                        )}
                      </p>
                      <span className="space-divided01">&nbsp;|&nbsp;</span>
                      <div className="star-rating d-float-center mt-0 mb-0">
                        <ul className="star-rate-sub d-float-center">
                          <li>
                            <i className="ri-star-fill ms-0 text-warning" />
                          </li>
                          <li>
                            <i className="ri-star-fill ms-0 text-warning" />
                          </li>
                          <li>
                            <i className="ri-star-fill ms-0 text-warning" />
                          </li>
                          <li>
                            <i className="ri-star-fill ms-0 text-warning" />
                          </li>
                          <li>
                            <i className="ri-star-line ms-0 text-warning" />
                          </li>
                        </ul>
                        <span className="review-text text-capitalize ms-2">
                          (4.1)
                        </span>
                      </div>
                    </div>
                    <p className="m-0 contact-info">
                      <span className="card-location font-regular">
                        {data_path?.suburb?.locality}(
                        {data_path?.suburb?.postcode}) +{" "}
                        <strong>{data_path?.serviceRadius}</strong> KMs
                      </span>
                      {/* &nbsp;|&nbsp;
              <span className="phone">
                <i className="ri-phone-line"></i> +{caregiver?.mobile}
              </span>
              &nbsp;|&nbsp;
              <span className="mail">
                <i className="ri-mail-line"></i> {caregiver?.email}
              </span> */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="d-float-center h-100 check-available-btn-top justify-content-end">
                  <p className="text-end m-0">
                    <a
                      className="btn btn-default2"
                      href="#"
                      onClick={() => setTab(2)}
                    >
                      Check Availability
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-bottom pt-10 pb-10"></div>
        </div>
      </section>
    </>
  );
}
