import { Button, Card } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import constants from "../../../Utils/Constants/Constants";
import { truncateText } from "../../../Utils/helpers/helpers";
import { Link } from "react-router-dom";

import React, { useState } from "react";
import CustomPagination from "../../../components/Pagination/Pagination";
import Nodata from "../../../components/Nodata/NodataFound";
import Pagination from "../../../components/Pagination/Pagination";

export default function CareGiverList({
  dataLoading,
  dataList,
  radius,
  handleChange,
  setValues,
  clearUrlParams,
  values,
  state_,
  currentPage,
  setCurrentPage,
}: any) {
  const [pages, setPages] = useState({
    page: 1,
    currentPage: dataList?.data?.meta?.currentPage,
    firstPage: dataList?.data?.meta?.total,
    firstPageUrl: "/?page=1",
    lastPage: dataList?.data?.meta?.lastPage,
    lastPageUrl: "",
    nextPageUrl: null,
    perPage: dataList?.data?.meta?.perPage,
    previousPageUrl: null,
    total: dataList?.data?.meta?.total,
  });
  console.log(pages, "pages");
  const goToPage = (page: any) => {
    // if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
    setCurrentPage(page);
    // }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    goToPage(currentPage - 1);
  };

  return (
    <>
      <style>
        {`
    
        `}
      </style>{" "}
      <div className="support-staff-finder">
        <div className="sub-staff-finder">
          <div className="row filter-div01">
            <div className=" mb-2 border-0">
              {/* <div className="d-float-center service-type-result col-12">
                <a
                  // href="javascript:void()"
                  className="btn btn-outline-secondary light-badge mb-0 me-2"
                >
                  Service Type:&nbsp;
                  <span className="search-output">
                    {dataList?.serviceTypeDetails?.name ||
                      values?.service_type?.label}{" "}
                  </span>
                </a>
                {values?.suburb_id && (
                  <>
                    {" "}
                    <a
                      // href="javascript:void()"
                      className="btn btn-outline-secondary light-badge mb-0 me-2"
                    >
                      Location:&nbsp;
                      <span className="search-output">
                        {values?.suburb_id?.label},{" "}
                      </span>
                    </a>
                  </>
                )}
                {(values?.state || state_) && (
                  <>
                    {" "}
                    <a
                      // href="javascript:void()"
                      className="btn btn-outline-secondary light-badge mb-0 me-2"
                    >
                      State:&nbsp;
                      <span className="search-output">
                        {state_ || values?.state?.label}
                      </span>
                    </a>
                  </>
                )}

                <a
                  // href="javascript:void()"
                  className="btn btn-outline-secondary light-badge mb-0 me-2"
                >
                  Radius:&nbsp;
                  <span className="search-output">
                    {radius ? radius : values?.radius ? values?.radius : 0} Km
                  </span>
                </a>
                <a
                  // href="javascript:void()"
                  onClick={() => clearUrlParams()}
                  className="btn btn-secondary light-badge mb-0 ms-2"
                >
                  <i className="ri-close-fill"></i> Clear
                </a>
              </div> */}
              <div className="col-xl-6 col-lg-5">
                <span className="text-small text-showing">
                  Showing <strong>{dataList?.data?.data?.length}</strong> of{" "}
                  <strong>{dataList?.data?.meta?.total} </strong> Care Givers
                </span>
              </div>
              <hr className="mt-2" />
            </div>
          </div>

          {/* <h4>Care Givers List</h4>
          <span>Search results -</span>
          <p>
            {" "}
            <span>
              Service Type :{dataList?.serviceTypeDetails?.name}{" "}
            </span>
          </p>
          <p>
            {" "}
            <span>Radius : {radius} Km
              
            </span>
          </p> */}

          {dataLoading ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                {dataList?.data?.data?.length === 0 ? (
                  <Nodata />
                ) : (
                  <>
                    {dataList?.data?.data?.map((item: any, index: any) => {
                      return (
                        <>
                          {/* Giver Lists */}
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 care-giver-lists">
                            <div className="card-grid-2 hover-up">
                              <div className="card-grid-2-image-left pb-0">
                                <div className="image-box">
                                  <img
                                    src={
                                      item?.user_details?.profileImgThumbnail
                                    }
                                  />
                                </div>
                                <div className="right-info">
                                  <a className="name-job" href="#">
                                    {item?.firstName}  {item?.lastName}
                                  </a>
                                  <div className="star-rating mb-2 d-float-center mt-1">
                                    <ul className="star-rate-sub d-float-center">
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-line ms-0 text-warning" />
                                      </li>
                                    </ul>
                                    <span className="review-text text-capitalize ms-0">
                                      (4.1)
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="right-info">
                                <span className="location-small">
                                  <i className="ri-map-pin-line ms-0" />
                                  {item?.user_details?.suburb?.locality} (
                                  {item?.user_details?.suburb?.postcode}) +{" "}
                                  <strong>
                                    {item?.user_details?.serviceRadius}
                                  </strong>{" "}
                                  KMs
                                </span>
                              </div>
                              <div className="card-block-info">
                                <p className="font-sm color-text-paragraph mt-0">
                                  {truncateText(
                                    item?.user_details?.aboutYourself,
                                    20
                                  )}
                                </p>
                                <div className="mt-5">
                                  <span className="speak-in text-dark f-13">
                                    <i className="ri-speak-line ms-0 text-dark f-15" />{" "}
                                    Speaks:&nbsp;
                                  </span>
                                  <span className="f-13 speak-out">
                                    {item?.user_details?.languages_spokens?.map(
                                      (items: any, index: number) => (
                                        <React.Fragment key={index}>
                                          {items?.language?.name}
                                          {index <
                                            item.user_details?.languages_spokens
                                              ?.length -
                                              1 && ", "}
                                        </React.Fragment>
                                      )
                                    )}
                                  </span>
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="card-2-bottom mt-0">
                                  <div className="row">
                                    <div className="col-lg-6 col-6 pt-1">
                                      <span className="card-text-price">
                                        $
                                        {item?.user_details
                                          ?.caregiver_charges?.[3]?.rate
                                          ? item?.user_details
                                              ?.caregiver_charges?.[3]?.rate
                                          : 0}
                                      </span>
                                      <span className="text-muted">/Hour</span>
                                    </div>
                                    <div className="col-lg-6 col-6 text-end">
                                      <div className="btn btn-apply-now">
                                        <Link
                                          className="text-white"
                                          to={`/care-giver-view?id=${item?.id}`}
                                        >
                                          View more
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="paginations m-0 mt-0 col-md-12 text-end">
                      <hr className="mt-0 mb-2" />
                      <Pagination
                        goToPreviousPage={goToPreviousPage}
                        goToPage={goToPage}
                        goToNextPage={goToNextPage}
                        totalPages={dataList?.data?.meta?.lastPage}
                        currentPage={currentPage}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
