import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from "reactstrap";
import constants from "../../../../Utils/Constants/Constants";

export default function Coustomer() {
  const slides = [
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}slider/logo/HHCC_logo.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}slider/logo/ndis.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}slider/logo/advertise-your-business.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}slider/logo/hec-paris.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}slider/logo/zeyos.png`,
    },
  ];
  const settings: any = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    // nextArrow: true,
    // prevArrow: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      {" "}
      <div className="container">
        <div className="list-brands mt-40 mb-30">
          <div className="box-swiper">
            <div className="swiper-container swiper-group-10 swiper">
              <div
                className="row justify-content-center"
                style={{ marginLeft: "0px", paddingInline: "10px" }}
              >
                <Slider {...settings}>
                  {" "}
                  {slides?.map((item: any, index: any) => {
                    return (
                      <>
                        {" "}
                        <div className="col-md-12 col-12" key={index}>
                          <a>
                            <img src={item?.backgroundImage} alt="" />
                          </a>
                        </div>
                      </>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
