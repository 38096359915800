import moment from "moment";
import { clearData } from "../../lib/keyStorage";
import { USER_ROLES } from "../Constants/AuthenticationConstants";
import { SERVICES_TYPES } from "../Constants/select_options";

function processJsonResponse(data: any, fields = ["id", "name"]) {
  let dataProcessedResponse = data?.map(function (obj: any) {
    obj["value"] = obj[fields[0]];
    obj["label"] = obj[fields[1]];
    delete obj[fields[0]];
    delete obj[fields[1]];
    return obj;
  });
  return dataProcessedResponse;
}

function shift_time(week_day: any) {
  return week_day === 7
    ? "Sunday"
    : week_day === 1
    ? "Monday"
    : week_day === 2
    ? "Tuesday"
    : week_day === 3
    ? "Wednesday"
    : week_day === 4
    ? "Thursday"
    : week_day === 5
    ? "Friday"
    : week_day === 6
    ? "Saturday"
    : "";
}
const AuthChecker = (navigate: any, path: string) => {
  if (localStorage.getItem("AUTH_TOKEN") !== null) {
    if (localStorage.getItem("ROLE") === USER_ROLES?.ADMIN) {
      navigate("/", { replace: true });
    } else if (localStorage.getItem("ROLE") === USER_ROLES?.CRAEGIVER) {
      navigate("/", { replace: true });
    } else if (localStorage.getItem("ROLE") === USER_ROLES?.CARESEEKER) {
      navigate("/", { replace: true });
    }
  } else {
    navigate(path, { replace: true });
  }
};
export const capitalizeFirstLetterOfEachWord = (input: string): string => {
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
function FirstLetterUpper(text: any) {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
}

const setValidationErrors = (errors: any, actions: any) => {
  Object?.keys(errors)?.map((fieldName) =>
    errors?.[fieldName]?.map((errorMessage: string, index: number) =>
      actions?.setFieldError(fieldName, errorMessage)
    )
  );
};
const Logout = async (navigate: Function) => {
  await clearData();
  navigate("/");
};
const ScrollToContainerTop = (ref: any) => {
  if (ref?.current) {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }
};

const setValidationErrorsAll = (errors: any, actions: any) => {
  errors?.forEach((error: any) => {
    const fieldName = Object.keys(error)[0];
    const errorMessage = Object.values(error)[0];
    actions?.setFieldError(fieldName, errorMessage);
  });
};

// Sun Oct 01 2023 00:00:00 GMT+0530 (India Standard Time) to yyyy-mm-dd format
const ISODateToDateConverter = (date: string) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const ISODateToDateConverter2 = (date: any) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month (months are zero-based) and pad with 0 if necessary
  const day = String(inputDate.getDate()).padStart(2, "0"); // Pad with 0 if necessary
  // Create the "yyyy-mm-dd" formatted string
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

function formatDateTimeForInput(dateTimeString: any) {
  const dateObject = new Date(dateTimeString);

  // Check if the dateObject is a valid date
  if (isNaN(dateObject.getTime())) {
    return "Invalid Date";
  }

  // Use toISOString to get UTC representation
  const formattedDateTime = dateObject.toISOString().slice(0, 10);

  return formattedDateTime;
}
const optionsWithIds = (optionsArray: any) => {
  return optionsArray?.map((name: any, index: number) => ({
    id: index + 1, // Assign an id starting from 1
    name: name, // Assign the name from the array
  }));
};
function truncateText(text: string, maxLength: number) {
  // Replace "Service Details" with an empty string if present
  const cleanedText = text?.replace("Service Details", "");

  // Check if the cleaned text exceeds the maxLength and truncate if necessary
  if (cleanedText?.length > maxLength) {
    return cleanedText?.substring(0, maxLength) + "...";
  }

  // If no truncation is needed, return the cleaned text
  return cleanedText;
}
const SERVICES_OPTIONS = SERVICES_TYPES?.map((type: any, index: any) => ({
  value: index + 1,
  label: type,
}));
const stepSwitchCareSeeker = (step: any, navigate: any) => {
  switch (step) {
    case 1:
      navigate("/create-profile-careseeker/1");
      break;
    case 2:
      navigate("/create-profile-careseeker/2");
      break;
    case 3:
      navigate("/create-profile-careseeker/3");
      break;
    case 4:
      navigate("/create-profile-careseeker/4");
      break;
    // Handle other steps as needed
    default:
      navigate("/create-profile-careseeker/1"); // Default to step 1 if step is out of bounds
  }
};
const getFilteredOptions = (selectedValue: any, options: any) => {
  console.log(selectedValue, "selectedValue");
  return options.filter((option: any) => !selectedValue.includes(option.value));
};
function calculateAge(dateOfBirth: any) {
  const today = new Date();
  const today_: any = moment(today, "YYYY-MM-DD");
  const momentDate = moment(dateOfBirth, "YYYY-MM-DD");

  let age = today_.year() - momentDate.year();
  const monthDifference = today_.month() - momentDate.month();
  console.log(age);
  if (age === 0) {
    const months =
      monthDifference >= 0 ? monthDifference : 12 + monthDifference;
    if (months == 0) {
      return `This month`;
    } else {
      return `${months} ${months === 1 ? "month" : "months"}`;
    }
  } else {
    return `${age} ${age === 1 ? "year" : "years"}`;
  }
}
function getDayName(day: number): string {
  const daysOfWeek = [
    "Sunday", // 0
    "Monday", // 1
    "Tuesday", // 2
    "Wednesday", // 3
    "Thursday", // 4
    "Friday", // 5
    "Saturday", // 6
  ];

  return daysOfWeek[day] || "Invalid day"; // Fallback for invalid day numbers
}
function convertISODateToTime(iso_date: any) {
  var date = new Date(iso_date);
  var hours = date.getHours();
  var minutes: any = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}
function dateConvertion(date: any) {
  return moment(date).format("DD MMM YYYY");
}
function TimeConvertion(date: any) {
  return moment(date).format("hh:mm A");
}
function convertTo12HourFormat(timeString: any) {
  // Split the time string into hours and minutes
  let [hours, minutes] = timeString.split(":");

  // Convert hours to a number
  hours = parseInt(hours);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Return the formatted time
  return `${hours}:${minutes} ${period}`;
}

const australianPhoneNumberRegex = /^(\+61|0)[2|3|4|7|8]\d{8}$/;
function removeHtmlTags(input: any) {
  const withoutTags = input?.replace(/<\/?[^>]+(>|$)/g, "");
  const withoutBullets = withoutTags.replace(/•/g, "");
  // Step 2: Replace HTML entities with plain spaces
  const withoutEntities = withoutBullets
    .replace(/&nbsp;/g, " ")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");

  // Step 3: Remove dots
  const cleanString = withoutEntities.replace(/\./g, "");

  // Return the cleaned string
  return cleanString?.trim();
}
function isNumeric(str: any) {
  const regex = /^[0-9]+$/;
  return regex.test(str);
}
async function fetchImageAsBinary(url: any) {
  try {
    // Fetch the image data from the URL
    const response = await fetch(url);

    // Check if the response is OK
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Convert response to ArrayBuffer
    const arrayBuffer = await response.arrayBuffer();

    // Convert ArrayBuffer to Uint8Array (binary format)
    const binaryData = arrayBuffer;

    return binaryData;
  } catch (error) {
    console.error("Error fetching image:", error);
  }
}
const calculateYearsOfExperience = (startDate: any, endDate: any) => {
  const start: any = new Date(startDate);
  const end: any = new Date(endDate);

  // Calculate the total difference in time
  const diffTime = Math.abs(end - start);

  // Calculate the number of years (by dividing milliseconds by the number of milliseconds in a year)
  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

  // Calculate the remainder time after the full years (to calculate months)
  const remainingTime = diffTime % (1000 * 60 * 60 * 24 * 365);

  // Calculate the number of months in the remaining time
  const diffMonths = Math.floor(remainingTime / (1000 * 60 * 60 * 24 * 30));

  console.log(diffYears, "years", diffMonths, "months");

  return { years: diffYears, months: diffMonths };
};
const calculateYearsOfExperience_ = (startDate: any, endDate: any) => {
  const start: any = new Date(startDate);
  const end: any = new Date(endDate);

  const diffTime = Math.abs(end - start); // Difference in milliseconds
  const totalDays = diffTime / (1000 * 60 * 60 * 24); // Convert milliseconds to total days

  // Calculate full years
  const diffYears = Math.floor(totalDays / 365);

  // Calculate remaining days after full years
  const remainingDays = totalDays % 365;

  // Convert remaining days to months (assuming 30.4 days per month)
  const diffMonths = Math.floor(remainingDays / 30.4);

  console.log(`${diffYears} years and ${diffMonths} months`);

  return [{ years: diffYears, months: diffMonths }];
};
// Function to format yyyy-mm-dd to dd/mm/yyyy
const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};
const formatDateForInput = (dateString: string) => {
  // Convert dd/mm/yyyy to yyyy-mm-dd
  const [day, month, year] = dateString.split("/");
  return `${year}-${month}-${day}`;
};
const getURLPassData = () => {
  const currentUrl = window.location.href;

  // Step 2: Create a URL object
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  const id = params.get("token");
  return id;
};
export {
  processJsonResponse,
  shift_time,
  AuthChecker,
  setValidationErrors,
  Logout,
  ScrollToContainerTop,
  setValidationErrorsAll,
  ISODateToDateConverter,
  ISODateToDateConverter2,
  formatDateTimeForInput,
  FirstLetterUpper,
  optionsWithIds,
  truncateText,
  SERVICES_OPTIONS,
  stepSwitchCareSeeker,
  getFilteredOptions,
  calculateAge,
  getDayName,
  convertISODateToTime,
  dateConvertion,
  TimeConvertion,
  convertTo12HourFormat,
  australianPhoneNumberRegex,
  removeHtmlTags,
  isNumeric,
  fetchImageAsBinary,
  calculateYearsOfExperience,
  calculateYearsOfExperience_,
  formatDate,
  getURLPassData
};
