import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import ReactSelect from "react-select";
import { useQuery } from "react-query";
import { getAustState, getLocationData, getLocationData_ } from "../../../Api/WebsiteApi";
import { daysOfWeek } from "../../../Utils/Constants/select_options";
import PrefixForm from "../../../components/RangeSlider/RangeSlider";
import {
  initialValuesStep_2,
  validationSchemaStep_2,
} from "../ProfileMethods/ProfileValidation";
import {
  addModalInitialValuesHandlerStep2,
  addModalInitialValuesHandlerStep4,
  ProfileSubmitHandlerStep2,
} from "../ProfileMethods/ProfileSubmitHandlers";
import Autocomplete from "../../CareSeekerProfile/Steps/components/AutoComplete";
import InputSelectCommon from "../../../components/CommonSelect/InputSelect";
import SelectComponent from "../../../components/ReactSelect/ReactSelect";

const Step2 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const [value, setValues] = useState<any>();
  const [inputValue, setInputValue] = useState<any>([]);
  const { data: location_ } = useQuery(
    [`data`, inputValue],
    () => getLocationData_(inputValue),
    { keepPreviousData: true, staleTime: 1000 }
  );
  
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep_2);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep4(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep_2);
    }
    setValues(
      Userdata?.user_details?.serviceRadius
        ? Userdata?.user_details?.serviceRadius
        : 0
    );
  }, [Userdata, Userdata?.id, value]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addModalInitialValues}
        validationSchema={validationSchemaStep_2}
        onSubmit={(values, actions) => {
          ProfileSubmitHandlerStep2(values, actions, setStep, UserListRefetch);
        }}
      >
        {({
          errors,
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setFieldTouched,
          touched
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const allSwitchesOn = values.availability_details.every(
              (detail) => detail.toggleSwitch
            );
            if (!allSwitchesOn) {
              setFieldValue("all_day_availability", false);
            }
          }, [
            values.availability_details,
            setFieldValue,
            values.all_day_availability,
          ]);

          const handleStartDateChange = (e: any) => {
            const { name, value } = e.target;
            setFieldValue(name, value);
            if (name === "start_time") {
              values.availability_details.forEach((_, index) => {
                setFieldValue(
                  `availability_details.${index}.start_time`,
                  !values.all_day_availability ? "" : value
                );
              });
            } else {
              values.availability_details.forEach((_, index) => {
                setFieldValue(
                  `availability_details.${index}.end_time`,
                  !values.all_day_availability ? " " : value
                );
              });
            }
          };

          return (
            <Form
              className="seeker-registration-form2 giver-register4"
              onSubmit={handleSubmit}
            >
              <FormGroup>
                <Label>Suburb</Label>
                <Autocomplete
                  setInputValue={setInputValue}
                  loaction_={location_}
                  setFieldValue={setFieldValue}
                  value_data={values?.suburb}
                  values={values}
                />
                <ErrorMessage
                  name="suburb"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              {/* <FormGroup>
                <Label>State</Label>
                <SelectComponent
                  options={state_aust} // Pass your options here
                  onChange={(selectedOption: any) =>
                    setFieldValue("state", [selectedOption])
                  }
                  onBlur={() => setFieldTouched("state", true)}
                  isClearable={true}
                  error={errors?.state && touched?.state ? errors.state : ""}
                  value={values?.state}
                  // isMulti={true}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-danger"
                />
              </FormGroup> */}

              <FormGroup className="mt-30 mb-30 service-radius-formgroup">
                <Label className="mb-0 " for="service_radius">
                  Service Radius
                </Label>{" "}
                <PrefixForm
                  rtl={false}
                  setFieldValue={setFieldValue}
                  name="service_radius"
                  value={values?.service_radius}
                />
                <div className="mt-0 text-center">
                  {values?.service_radius} km Radius
                </div>
                <ErrorMessage
                  name="service_radius"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>

              <Row className="availability-row-form">
                <div className="col-md-12">
                  <Label className=" mb-20">Availability</Label>
                </div>
                <Col lg="4">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={values.all_day_availability}
                      name="all_day_availability"
                      onChange={() =>
                        setFieldValue(
                          "all_day_availability",
                          !values.all_day_availability
                        )
                      }
                    />
                    &nbsp;
                    <Label className="mb-0" check>
                      All Day Availability
                    </Label>
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label for="start_time">Start Time</Label>
                    <Field
                      type="time"
                      name="start_time"
                      className="form-control"
                      value={
                        !values.all_day_availability ? "" : values?.start_time
                      }
                      onChange={handleStartDateChange}
                      disabled={!values.all_day_availability}
                    />
                    <ErrorMessage
                      name="start_time"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label for="end_time">End Time</Label>
                    <Field
                      type="time"
                      name="end_time"
                      className="form-control"
                      value={
                        !values.all_day_availability ? "" : values.end_time
                      }
                      onChange={handleStartDateChange}
                      disabled={!values.all_day_availability}
                    />
                    <ErrorMessage
                      name="end_time"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <hr />

              <FieldArray name="availability_details">
                {({ insert, remove, push }) => (
                  <>
                    {values?.availability_details?.length > 0 &&
                      values?.availability_details?.map((detail, index) => (
                        <div key={index} className="mb-3">
                          <Row>
                            <Col>
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  checked={detail?.toggleSwitch}
                                  name={`availability_details.${index}.toggleSwitch`}
                                  onChange={() =>
                                    setFieldValue(
                                      `availability_details.${index}.toggleSwitch`,
                                      !detail.toggleSwitch
                                    )
                                  }
                                />
                                <Label className="mb-0" check>
                                  {detail.toggleSwitch
                                    ? "Available"
                                    : "Not Available"}
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label
                                  for={`availability_details.${index}.day`}
                                >
                                  Day
                                </Label>
                                <Field
                                  as="select"
                                  name={`availability_details.${index}.day`}
                                  className="form-control"
                                  disabled
                                >
                                  {daysOfWeek
                                    .filter(
                                      (day) =>
                                        !values.availability_details.some(
                                          (detail, i) =>
                                            detail.day === day.value &&
                                            i !== index
                                        )
                                    )
                                    .map((day) => (
                                      <option key={day.value} value={day.value}>
                                        {day.label}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  name={`availability_details.${index}.day`}
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label
                                  for={`availability_details.${index}.start_time`}
                                >
                                  Start Time
                                </Label>
                                <Field
                                  type="time"
                                  name={`availability_details.${index}.start_time`}
                                  className="form-control"
                                  value={
                                    !detail.toggleSwitch &&
                                    !values?.all_day_availability
                                      ? ""
                                      : detail.toggleSwitch
                                      ? values.availability_details?.[index]
                                          .start_time
                                      : ""
                                  }
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      `availability_details.${index}.start_time`,
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name={`availability_details.${index}.start_time`}
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label
                                  for={`availability_details.${index}.end_time`}
                                >
                                  End Time
                                </Label>
                                <Field
                                  type="time"
                                  name={`availability_details.${index}.end_time`}
                                  className="form-control"
                                  value={
                                    !detail.toggleSwitch &&
                                    !values?.all_day_availability
                                      ? ""
                                      : detail.toggleSwitch
                                      ? values.availability_details?.[index]
                                          .end_time
                                      : ""
                                  }
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      `availability_details.${index}.end_time`,
                                      e.target.value
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name={`availability_details.${index}.end_time`}
                                  component="div"
                                  className="text-danger"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </>
                )}
              </FieldArray>

              <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                <Button
                  className="btn btn-brand-2"
                  type="submit"
                  onClick={() => [setStep(3)]}
                >
                  <i className="ri-arrow-left-line"></i>&nbsp;Back
                </Button>
                <Button
                  className="btn btn-brand-1"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Spinner size="sm"></Spinner>}
                  Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step2;
