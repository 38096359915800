/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container } from "reactstrap";
import constants from "../../../../Utils/Constants/Constants";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const ServiceDetiles = ({ data, servicelist }: any) => {
  const navigate = useNavigate();
  const handleClick = (id: any) => {
    console.log(id,"id")
    navigate(`/services-detailes?id=${id}`);
  };
  const [isLoading, setIsLoading] = useState(true);
  return (
    <section className="section-box mt-10">
      <Container className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-12 services-view01">
            <div className="box-border-single">
              <div className="row mt-10">
                <div className="col-lg-8 col-md-12">
                  <h3>{data?.name}</h3>
                </div>
                <div className="col-lg-4 col-md-12 text-lg-end">
                  <Link
                    className="btn btn-apply-icon btn-apply btn-apply-big hover-up"
                    to={`/find/caregivers?service_type_id=${data?.id}`}
                  >
                    Find Care Giver
                  </Link>
                </div>
              </div>
              <div className="border-bottom pt-10 pb-10"></div>
              <div className="banner-hero banner-image-single mt-10 mb-20">
                <img src={`${data?.bannerImg}`} alt="Service Type" />
              </div>
              <div className="content-single">
                {/* <h4>Service Details</h4> */}
                <p>
                  <strong>
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.details }}
                    ></div>
                  </strong>
                </p>
                {/* <h4>Registration</h4>
                <p>{data?.registration}</p>
                <h4>Education</h4>
                <p>{data?.education}</p> */}
              </div>
              <div className="author-single">
                <span>{data?.name}</span>
              </div>
              <div className="single-apply-jobs">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <Link
                      className="btn btn-apply-icon btn-apply btn-apply-big hover-up"
                      to={`/find/caregivers?service_type_id=${data?.id}`}
                    >
                      Find Care Giver
                    </Link>
                  </div>
                  <div className="col-md-7 text-lg-end social-share">
                    <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-10">
                      Share this
                    </h6>
                    <a className="mr-5 d-inline-block d-middle" href="#">
                      <img
                        alt="Share on Facebook"
                        src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/share-fb.svg`}
                      />
                    </a>
                    <a className="mr-5 d-inline-block d-middle" href="#">
                      <img
                        alt="Share on Twitter"
                        src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/share-tw.svg`}
                      />
                    </a>
                    <a className="d-inline-block d-middle" href="#">
                      <img
                        alt="Share on WhatsApp"
                        src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/share-whatsapp.svg`}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
            <div className="sidebar-border">
              <h6 className="f-18">Our Location</h6>
              <div className="sidebar-list-job">
                <div className="box-map mb-0">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13804065.984854717!2d139.4190549947332!3d-32.36196953102922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0dcb74f75e4b0d%3A0x1780af1122c49f2d!2sNew%20South%20Wales%2C%20Australia!5e0!3m2!1sen!2sin!4v1724070338610!5m2!1sen!2sin"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="sidebar-border other-services12">
              <h6 className="f-18">Other Services</h6>
              <div className="sidebar-list-job">
                <ul className="similar-service-ul01">
                  {/* Map over a list of services to avoid repetition */}
                  {servicelist?.map((service: any, index: any) => (
                    <li key={index}>
                      <div className="card-list-4 wow animate__animated animate__fadeIn hover-up">
                        <div className="image">
                          <a href="#">
                          {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span>Loading...</span> {/* This could be a spinner */}
        </div>
      )}
                            <img
                              src={service?.thumbnailImg}
                              alt="service"
                              className="image"
                              loading="lazy"
                              onLoad={() => setIsLoading(false)} 
                            />
                          </a>
                        </div>
                        <div className="info-text">
                          <h5 className="font-md font-bold color-brand-1">
                            <a
                             
                              onClick={() => handleClick(service?.id)}
                            >
                              {" "}
                              {service?.name}
                            </a>
                          </h5>
                          <div className="service-info-box01 mt-5">
                            <div className="row">
                              <div className="col-12">
                                <h6 className="card-price">
                                  {service?.serviceProvidersCount}{" "}
                                  <span className="font-weight-bold">
                                    Care Givers
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ServiceDetiles;
