import ReactSelect from "react-select";
import { Button } from "reactstrap";

export default function Filter({
  serviceTypes,
  handleChange,
  setValues,
  state,
  setLocation,
  location_data,
  Lang_data,
  List_data,
  values,
  setSatteInput,
  clearValues,
  inter__data,
  exp_aged_data,
  exp_child_data,
  willing__data,
  clearUrlParams
}: any) {
  console.log(
    serviceTypes?.filter(
      (option: any) => option.value !== values?.service_type
    ),
    "location_data",
    values?.service_type
  );
  return (
    <>
      {" "}
      <form id="filter_form">
        <div className="row mt-30">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Service Type</label>
              {/* <select
                id="profile_type"
                name="profile_type"
                className="form-control multi-select"
              >
                <option value="" disabled selected>
                  - Select -
                </option>
              </select> */}
              <ReactSelect
                options={serviceTypes}
                name="service_type"
                onChange={(value: any) => {
                  handleChange("service_type", value);
                }}
                value={serviceTypes?.find(
                  (option: any) =>
                    option.value?.toString() === values?.service_type
                )}
                isClearable
              />
            </div>
          </div>
          {/* <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>State</label>
              <ReactSelect
                options={state}
                name="state"
                onChange={(value: any) => {
                  handleChange("state", value);
                }}
                value={state?.find(
                  (option: any) => option.label?.toString() === values?.state
                )}
                onInputChange={(value: any) => {
                  setSatteInput(value);
                }}
              />
              <span className="text-danger" id="stateError"></span>
            </div>
          </div> */}
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Suburb</label>
              <div className="box-location">
                <ReactSelect
                  options={location_data}
                  name="suburb_id"
                  onChange={(value: any) => {
                    handleChange("suburb_id", value?.value);
                  }}
                  onInputChange={(value: any) => {
                    setLocation(value);
                  }}
                  value={location_data?.find(
                    (option: any) =>
                      option.value?.toString() === values?.suburb_id
                  )}
                  isClearable
                />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Radius</label>
              <input
                type="text"
                className="form-control"
                name="radius"
                placeholder="Radius"
                onChange={(e: any) => handleChange("radius", e.target.value)}
              />
            </div>
          </div> */}
          {/* <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Postcode</label>
              <input
                type="text"
                className="form-control"
                name="post_code"
                placeholder="Postcode"
                onChange={(e: any) => handleChange("post_code", e.target.value)}
              />
            </div>
          </div> */}
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Gender</label>
              <select
                id="gender"
                name="gnder"
                className="form-control multi-select"
                onChange={(e: any) => handleChange("gnder", e.target.value)}
              >
                <option value="">- Select -</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Other</option>
              </select>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Languages</label>
              <ReactSelect
                options={Lang_data}
                isMulti
                name="lang"
                onChange={(value: any) => {
                  handleChange("lang", value);
                }}
                onInputChange={(value: any) => {
                  setLocation(value);
                }}
                isClearable
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Willing to work</label>
              <ReactSelect
                options={willing__data}
                isMulti
                name="willing__data"
                onChange={(value: any) => {
                  handleChange("willing__data", value);
                }}
                isClearable
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Experinced with aged carers</label>
              <ReactSelect
                options={exp_aged_data}
                isMulti
                name="exp_aged_data"
                onChange={(value: any) => {
                  handleChange("exp_aged_data", value);
                }}
                isClearable
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Experinced with childrens</label>
              <ReactSelect
                options={exp_child_data}
                isMulti
                name="exp_child_data"
                onChange={(value: any) => {
                  handleChange("exp_child_data", value);
                }}
                isClearable
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Interset</label>
              <ReactSelect
                options={inter__data}
                isMulti
                name="inter__data"
                onChange={(value: any) => {
                  handleChange("inter__data", value);
                }}
                isClearable
              />
            </div>
          </div>
          <div className="col-md-12 text-right mb-3">
            <Button
              type="button"
              //   onClick={handleReset}
              className="btn btn-secondary btn-sm pull-left"
              title="Reset"
              onClick={() => {
                clearUrlParams();
              }}
            >
              <i className="ri-close-line"></i> Clear
            </Button>
            <Button
              onClick={() => List_data()}
              className="btn btn-default btn-sm pull-right"
              title="Apply Filter"
            >
              <i className="ri-search-line"></i> Search
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
