import * as Yup from "yup";
import { RATES } from "../../../Utils/Constants/select_options";
import moment from "moment";
import { australianPhoneNumberRegex } from "../../../Utils/helpers/helpers";

const driversLicenseRegex = /^\d{8}$/; // 8 digits
const policeVerificationRegex = /^\d{7}PC$/; // 7 digits followed by PC
const wwccIdRegex = /^WWC\d{7}[A-Z]$/; // WWC followed by 7 digits and 1 alphabet
const firstAidIdRegex = /^[a-zA-Z0-9]{6,14}$/; // 6-14 alphanumeric characters
const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/; // Date in dd/mm/yyyy format

export const validationSchema = Yup.object().shape({
  profile_type: Yup.string().required("Service type is required"), // Adjust the allowed profile types as needed
  name: Yup.string().required("Full Name is required"),
  dob: Yup.date()
    .nullable()
    .required("Date of birth is required")
    .test("dob", "You must be 18 years or older", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }),
  gender: Yup.number()
    .required("Gender is required")
    .oneOf([1, 2, 3], "Invalid gender option"), // Assuming 1 = Male, 2 = Female, 3 = Other
  residency_status: Yup.number()
    .required("Residency status is required")
    .oneOf([1, 2, 3], "Invalid residency status"), // Adjust the allowed statuses as needed
  address: Yup.string()
    .required("Address is required")
    .min(5, "Address must be at least 5 characters long")
    .max(100, "Address cannot be more than 100 characters long"),
  // profile_img: Yup.string().required("Field is required"),
  profile_img: Yup.mixed().required("Image is required"),

  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(
      australianPhoneNumberRegex,
      "Mobile number must be a valid Australian mobile number, either in the format +614XXXXXXXX or 04XXXXXXXX"
    )
    .required("Phone number is required"),
});
export const validationSchemaStep_2 = Yup.object().shape({
  // step: Yup.number().required(),
  suburb: Yup.string().required("Suburb is required"),
  service_radius: Yup.number()
    .required("Service radius is required")
    .positive("Service radius is required"),
  all_day_availability: Yup.boolean(),
  start_time: Yup.string().when("all_day_availability", {
    is: true,
    then: (schema) => schema.required("Start time is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  end_time: Yup.string()
    .when("all_day_availability", {
      is: true,
      then: (schema) => schema.required("End time is required"),
      otherwise: (schema) => schema.notRequired(),
    })
    .test(
      "is-greater",
      "End time must be later than start time",
      function (value) {
        const { start_time, toggleSwitch } = this.parent;
        if (toggleSwitch && start_time && value) {
          return (
            new Date(`1970/01/01 ${value}`) >
            new Date(`1970/01/01 ${start_time}`)
          );
        }
        return true; // If toggleSwitch is false or no start_time or end_time is provided, pass the validation.
      }
    ),
  availability_details: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().when("toggleSwitch", {
        is: true,
        then: (schema) => schema.required("Day is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      start_time: Yup.string().when("toggleSwitch", {
        is: true,
        then: (schema) => schema.required("Start time is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      end_time: Yup.string()
        .when("toggleSwitch", {
          is: true,
          then: (schema) => schema.required("End time is required"),
          otherwise: (schema) => schema.notRequired(),
        })
        .test(
          "is-greater",
          "End time must be later than start time",
          function (value) {
            const { start_time, toggleSwitch } = this.parent;
            if (toggleSwitch && start_time && value) {
              return (
                new Date(`1970/01/01 ${value}`) >
                new Date(`1970/01/01 ${start_time}`)
              );
            }
            return true; // If toggleSwitch is false or no start_time or end_time is provided, pass the validation.
          }
        ),
      // hourly_rate: Yup.number().when("toggleSwitch", {
      //   is: true,
      //   then: (schema) =>
      //     schema
      //       .required("Hourly rate is required")
      //       .positive("Hourly rate must be a positive number"),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
      toggleSwitch: Yup.boolean().required(),
    })
  ),
});

export const validationSchema_3 = Yup.object().shape({
  document_id: Yup.string()
    .required("Document ID is required")
    .matches(driversLicenseRegex, "Document ID must be 8 digits"),

  document_id_expire: Yup.string()
    .required("Document ID expiry date is required")
    .matches(dateRegex, "Expiry date must be in dd/mm/yyyy format"),

  document_id_img: Yup.mixed().required("Document ID image is required"),

  police_verification_id: Yup.string()
    .required("Police verification ID is required")
    .matches(
      policeVerificationRegex,
      "Police verification ID must be 7 digits followed by 'PC'"
    ),

  police_verification_id_expire: Yup.string()
    .required("Police verification ID expiry date is required")
    .matches(dateRegex, "Expiry date must be in dd/mm/yyyy format"),

  police_verification_id_img: Yup.mixed().required(
    "Police verification ID image is required"
  ),

  wwcc_id: Yup.string()
    .required("WWCC ID is required")
    .matches(
      wwccIdRegex,
      "WWCC ID must be 'WWC' followed by 7 digits and 1 alphabet"
    ),

  wwcc_id_expire: Yup.string()
    .required("WWCC ID expiry date is required")
    .matches(dateRegex, "Expiry date must be in dd/mm/yyyy format"),

  wwcc_id_img: Yup.mixed().required("WWCC ID image is required"),

  first_aid_certificate_id: Yup.string()
    .required("First aid certificate ID is required")
    .matches(
      firstAidIdRegex,
      "First aid certificate ID must be 6-14 alphanumeric characters"
    ),

  first_aid_certificate_expire: Yup.string()
    .required("First aid certificate expiry date is required")
    .matches(dateRegex, "Expiry date must be in dd/mm/yyyy format"),

  first_aid_certificate_img: Yup.mixed().required(
    "First aid certificate image is required"
  ),
});

export const validationSchema_4 = Yup.object({
  // step: Yup.number().required("Step is required"),
  about_yourself: Yup.string().required("About Yourself is required"),
  okay_with_pets: Yup.string().required("Okay with Pets is required"),
  work_experiences: Yup.array().of(
    Yup.object({
      company_name: Yup.string().required("Company Name is required"),
      designation: Yup.string().required("Designation is required"),
      start_date: Yup.date()
        .required("Start Date is required")
        .max(new Date(), "Start Date cannot be in the future"), // Ensures start_date is not in the future
      end_date: Yup.date()
        .nullable()
        .when("is_current_job", {
          is: false,
          then: (schema) =>
            schema
              .required("End date is required")
              .min(
                Yup.ref("start_date"),
                "End Date cannot be before the Start Date"
              ) // Ensures end_date is after or on start_date
              .max(new Date(), "End date cannot be in the future"), // Ensures end_date is not in the future
          otherwise: (schema) => schema.notRequired(),
        }),
      description: Yup.string().required("Description is required"),
    })
  ),

  qualifications: Yup.array().of(
    Yup.object({
      course_name: Yup.string().required("Course Name is required"),
      institution_name: Yup.string().required("Institution Name is required"),
      start_date: Yup.date()
        .required("Start Date is required")
        .max(new Date(), "Start Date cannot be in the future"), // Ensures start_date is not in the future
      end_date: Yup.date()
        .nullable()
        .when("is_current_study", {
          is: false,
          then: (schema) =>
            schema
              .required("End date is required")
              .min(
                Yup.ref("start_date"),
                "End Date cannot be before the Start Date"
              ) // Ensures end_date is after or on start_date
              .max(new Date(), "End date cannot be in the future"), // Ensures end_date is not in the future
          otherwise: (schema) => schema.notRequired(),
        }),
      is_current_study: Yup.boolean(),
      description: Yup.string().required("Description is required"),
    })
  ),
  languages_spokens: Yup.array()
    .min(1, "At least one Language must be selected")
    .required("Language is required"),

  interests: Yup.array()
    .min(1, "At least one interest must be selected")
    .required("Interest is required"),
  care_tasks: Yup.array()
    .min(1, "At least one care task must be selected")
    .required("Care tasks is required"),
  willing_to_work: Yup.array()
    .min(1, "At least one willing to work option must be selected")
    .required("Willing to work  is required"),
  experience_with_aged_cares: Yup.array()
    .min(1, "At least one experience with aged care must be selected")
    .required("Experience with aged care is required"),
  experience_with_children: Yup.array()
    .min(1, "At least one experience with children must be selected")
    .required("Experience with children  is required"),
});

export const validationSchema_5 = Yup.object({
  extra_charge_after_hours: Yup.boolean().required(
    "Extra charge field is required"
  ),
  abn: Yup.string()
    .matches(/^\d{11}$/, "ABN must be exactly 11 digits")
    .required("ABN is required"),
  bank_name: Yup.string().required("Bank name is required"),
  bank_account_number: Yup.string()
    .required("Bank account number is required")
    .matches(/^\d{6}$/, "Bank account number must be exactly 6 digits"),
  bank_branch_name: Yup.string()
    .matches(/^\d{3}-\d{3}$/, "BSB must be in the format XXX-XXX")
    .required("BSB is required"),
  rates: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      min: Yup.string().required("Rate is required"),

      // max: Yup.string()
      //   .required("Max rate is required")
      //   .test(
      //     "is-greater-than-min",
      //     "Max rate must be greater than min rate",
      //     function (value) {
      //       return parseFloat(value) > parseFloat(this.parent.min);
      //     }
      //   ),
    })
  ),
});

export const Step3Validation = (formData: any) => {
  let newErrors: any = {};

  // Regular expressions for each document ID format
  const driversLicenseRegex = /^\d{8}$/; // 8 digits
  const policeVerificationRegex = /^\d{7}PC$/; // 7 digits followed by PC
  const wwccIdRegex = /^WWC\d{7}[A-Z]$/; // WWC followed by 7 digits and 1 alphabet
  const firstAidIdRegex = /^[a-zA-Z0-9]{6,14}$/; // 6-14 alphanumeric characters
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/; // Date in dd/mm/yyyy format

  // Validate Drivers License ID
  if (!formData.document_id) {
    newErrors.document_id = "Document ID is required";
  } else if (!driversLicenseRegex.test(formData.document_id)) {
    newErrors.document_id = "Document ID must be 8 digits";
  }

  // Validate Document ID Expiry Date
  if (!formData.document_id_expire) {
    newErrors.document_id_expire = "Document ID expiry date is required";
  } else if (!dateRegex.test(formData.document_id_expire)) {
    newErrors.document_id_expire = "Expiry date must be in dd/mm/yyyy format";
  }

  // Validate Document ID Image
  if (!formData.document_id_img) {
    newErrors.document_id_img = "Document ID image is required";
  }

  // Validate Police Verification ID Expiry Date
  if (!formData.police_verification_id_expire) {
    newErrors.police_verification_id_expire =
      "Police verification ID expiry date is required";
  } else if (!dateRegex.test(formData.police_verification_id_expire)) {
    newErrors.police_verification_id_expire =
      "Expiry date must be in dd/mm/yyyy format";
  }

  // Validate Police Verification Document ID
  if (!formData.police_verification_id_img) {
    newErrors.police_verification_id_img =
      "Police verification ID image is required";
  }
  if (!formData.police_verification_id) {
    newErrors.police_verification_id = "Police verification ID is required";
  } else if (!policeVerificationRegex.test(formData.police_verification_id)) {
    newErrors.police_verification_id =
      "Police verification ID must be 7 digits followed by 'PC'";
  }

  // Validate WWCC ID
  if (!formData.wwcc_id) {
    newErrors.wwcc_id = "WWCC ID is required";
  } else if (!wwccIdRegex.test(formData.wwcc_id)) {
    newErrors.wwcc_id =
      "WWCC ID must be 'WWC' followed by 7 digits and 1 alphabet";
  }

  // Validate WWCC Expiry Date
  if (!formData.wwcc_id_expire) {
    newErrors.wwcc_id_expire = "WWCC ID expiry date is required";
  } else if (!dateRegex.test(formData.wwcc_id_expire)) {
    newErrors.wwcc_id_expire = "Expiry date must be in dd/mm/yyyy format";
  }

  // Validate WWCC ID Image
  if (!formData.wwcc_id_img) {
    newErrors.wwcc_id_img = "WWCC ID image is required";
  }

  // Validate First Aid Certificate ID
  if (!formData.first_aid_certificate_id) {
    newErrors.first_aid_certificate_id = "First aid certificate ID is required";
  } else if (!firstAidIdRegex.test(formData.first_aid_certificate_id)) {
    newErrors.first_aid_certificate_id =
      "First aid certificate ID must be 6-14 alphanumeric characters";
  }

  // Validate First Aid Certificate Expiry Date
  if (!formData.first_aid_certificate_expire) {
    newErrors.first_aid_certificate_expire =
      "First aid certificate expiry date is required";
  } else if (!dateRegex.test(formData.first_aid_certificate_expire)) {
    newErrors.first_aid_certificate_expire =
      "Expiry date must be in dd/mm/yyyy format";
  }

  // Validate First Aid Certificate Image
  if (!formData.first_aid_certificate_img) {
    newErrors.first_aid_certificate_img =
      "First aid certificate image is required";
  }

  return newErrors;
};

export const initialValues = {
  // step: 1,
  profile_type: null,
  dob: "",
  gender: "",
  residency_status: null,
  address: "",
  profile_img: "",
  email: "",
  mobile: "",
  profile_img_: "",
  name: "",
};

export const initialValuesStep_2 = {
  step: 2,
  suburb: "",
  service_radius: 0,
  all_day_availability: true,
  start_time: "",
  end_time: "",
  availability_details: [
    {
      id: 1,
      day: "1",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
    {
      id: 1,
      day: "2",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
    {
      id: 1,
      day: "3",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
    {
      id: 1,
      day: "4",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
    {
      id: 1,
      day: "5",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
    {
      id: 1,
      day: "6",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
    {
      id: 1,
      day: "7",
      start_time: "",
      end_time: "",
      // hourly_rate: "",
      toggleSwitch: true,
    },
  ],
  address: "",
  state: "",
};

export const initialValues_3 = {
  step: 1,
  document_id: "",
  document_id_expire: "",
  document_id_img: null, // Use null for FileBuffer as it will be a file object
  // police_verification_id: "",
  police_verification_id_expire: "",
  police_verification_id_img: null, // Use null for FileBuffer as it will be a file object
  wwcc_id: "",
  wwcc_id_expire: "",
  wwcc_id_img: null, // Use null for FileBuffer as it will be a file object
  first_aid_certificate_id: "",
  first_aid_certificate_expire: "",
  first_aid_certificate_img: null, // Use null for FileBuffer as it will be a file object
};
export const initialValues_4 = {
  step: 4,
  about_yourself: "",
  okay_with_pets: "",
  work_experiences: [
    {
      company_name: "",
      designation: "",
      start_date: null,
      end_date: null,
      is_current_job: false,
      description: "",
    },
  ],
  qualifications: [
    {
      course_name: "",
      institution_name: "",
      start_date: null,
      end_date: null,
      is_current_study: false,
      description: "",
    },
  ],
  languages_spokens: [],
  interests: [],
  care_tasks: [],
  willing_to_work: [],
  experience_with_aged_cares: [],
  experience_with_children: [],
};
export const initialValues_5 = {
  step: 5,
  extra_charge_after_hours: false,
  rates: RATES.map((rate: any) => ({
    id: rate.value,
    value: "",
    label: rate?.label,
    field: rate?.field,
    // max: "",
    min: "",
  })),
  abn: "",
  bank_name: "",
  bank_branch_name: "",
  bank_account_number: "",
  company_name: "",
};
