import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { YES_NO } from "../../../Utils/Constants/select_options";
import {
  initialValues_4,
  validationSchema_4,
} from "../ProfileMethods/ProfileValidation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  addModalInitialValuesHandlerStep2,
  ProfileSubmitHandlerStep4,
} from "../ProfileMethods/ProfileSubmitHandlers";
import { useQuery } from "react-query";
import {
  getExpAgedCareData,
  getExpChildrenData,
  getIntersetData,
  getLangData,
  getServiceData,
  getWillingToWorkData,
} from "../../../Api/WebsiteApi";
import AddExperince from "./SubSections/Step4/AddExpenice";
import AddEducation from "./SubSections/Step4/AddEducation";
import AdditionalInformation from "./SubSections/Step4/AdditionalInformation";

const Step4 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValues_4);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep2(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValues_4);
    }
  }, [Userdata, Userdata?.id]);
  const { data: languages }: any = useQuery(
    [`languages`],
    () => getLangData(),
    {
      keepPreviousData: true,
    }
  );
  const { data: interest }: any = useQuery(
    [`interest`],
    () => getIntersetData(),
    {
      keepPreviousData: true,
    }
  );
  const { data: willing_to_work }: any = useQuery(
    [`willing_to_work`],
    () => getWillingToWorkData(),
    {
      keepPreviousData: true,
    }
  );
  const { data: experience_with_aged_cares }: any = useQuery(
    [`experience_with_aged_cares`],
    () => getExpAgedCareData(),
    {
      keepPreviousData: true,
    }
  );
  const { data: experience_with_children }: any = useQuery(
    [`experience_with_children`],
    () => getExpChildrenData(),
    {
      keepPreviousData: true,
    }
  );
  const { data: care_tasks }: any = useQuery(
    [`care_tasks_`],
    () => getServiceData(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addModalInitialValues}
        validationSchema={validationSchema_4}
        onSubmit={(values, actions) => {
          console.log(values, "hjhjjhjh");
          //   navigate("/success?profile_completed=1");
          ProfileSubmitHandlerStep4(values, actions, setStep, UserListRefetch);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            {" "}
            {console.log(values, "zchzxczxgc", errors)}
            <Form onSubmit={handleSubmit} className="form-step4">
              <div className="text-center mb-3 logo-div01">
                <h3 className="block-subTitle text-center uss-text-info">
                  {/* <span className="uss-text-primary">Create</span> Account */}
                </h3>
              </div>

              <FormGroup>
                <Label for="about_yourself">About Yourself</Label>
                <Field
                  as="textarea"
                  name="about_yourself"
                  className={`form-control ${
                    errors.about_yourself && touched.about_yourself
                      ? "is-invalid"
                      : ""
                  }`}
                  style={{ height: "100px" }}
                />
                <ErrorMessage
                  name="about_yourself"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>

              <FormGroup>
                <Label>Are you okay with pets?</Label>

                <Row>
                  {YES_NO?.map((item: any, index: number) => {
                    return (
                      <div className="col-2" key={index}>
                        <Field
                          type="radio"
                          name="okay_with_pets"
                          value={item.id}
                          // className={`form-check-input ${
                          //   errors.okay_with_pets && touched.okay_with_pets
                          //     ? "is-invalid"
                          //     : ""
                          // }`}
                          checked={
                            values?.okay_with_pets === item?.id?.toString()
                          }
                        />
                        &nbsp;
                        <Label className="form-check-label">{item.name}</Label>
                      </div>
                    );
                  })}
                  <ErrorMessage
                    name="okay_with_pets"
                    component="div"
                    className="text-danger"
                  />
                </Row>
              </FormGroup>

              <Card>
                <CardBody>
                  <AddExperince
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </CardBody>
              </Card>
              <Card className="mt-3 mb-3">
                <CardBody>
                  <AddEducation
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </CardBody>
              </Card>
              <AdditionalInformation
                languages={languages}
                interest={interest}
                care_tasks={care_tasks}
                willing_to_work={willing_to_work}
                experience_with_aged_cares={experience_with_aged_cares}
                experience_with_children={experience_with_children}
                values={values}
                errors={errors}
                touched={touched}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
              <div className="text-center d-float-center justify-content-center gap-15">
                <Button
                  className="btn btn-brand-2"
                  type="submit"
                  onClick={() => [setStep(1)]}
                >
                  <i className="ri-arrow-left-line"></i>&nbsp;BACK
                </Button>
                <Button
                  className="btn btn-brand-1"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Spinner size="sm"></Spinner>}
                  Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
export default Step4;
