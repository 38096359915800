import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import ErrorPage from '../../Public/ErrorPage';
import { childrenType } from '../../../Types';
import LayoutContainer from '../../../components/Layouts';

const BrowserRouterProvider:React.FC<childrenType> = ({ children} ) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutContainer />}>
                    {children}
                    <Route path='*' element={<ErrorPage/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default BrowserRouterProvider