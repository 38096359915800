import * as Yup from "yup";
import { australianPhoneNumberRegex } from "../../../../../Utils/helpers/helpers";

// validation schema

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(100, "First name must be 100 characters or less")
    .required("First name is required"),
  last_name: Yup.string()
    .max(100, "Last name must be 100 characters or less")
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(
      australianPhoneNumberRegex,
      "Mobile number must be a valid Australian mobile number, either in the format +614XXXXXXXX or 04XXXXXXXX"
    )
    .required("Phone number is required"),

  agree_to_term_and_privacy: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms of Service and Privacy Policy"
  ),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(100, "Password must be 100 characters or less")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Password doesn't match with Confirm password "
    )
    .required("Confirm password is required"),
});

export const OtpValidationSchema = Yup.object().shape({
  otp: Yup.array()
    .required("OTP is required")
    .length(4, "Please enter OTP")
    .test(
      "validate-digits",
      "Each OTP field must contain exactly 1 digit",
      function (value) {
        if (value && value.length === 4) {
          return value.every((val) => /^[0-9]$/.test(val));
        }
        return true; // Skip individual validation if length is not 5
      }
    ),
});

// initial values
export const initializeRegisterInitialValues = {
  email: "",
  agree_to_term_and_privacy: false,
  mobile: "",
  first_name: "",
  password: "",
  confirm_password: "",
  last_name: "",
};
