import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { isNumeric } from "../../../../Utils/helpers/helpers";

function Autocomplete({
  setInputValue,
  loaction_,
  setFieldValue,
  value_data,
  values,
}: any) {
  const [serch_value, setSerchvalue] = useState("");

  const [initialItem, setInitialItem] = useState<any>();

  // useEffect(() => {
  //   // Find the item in the location_ array based on the value_data (id)
  //   if (value_data && loaction_) {
  //     const foundItem = loaction_?.find((item: any) => item.id === value_data);
  //     if (foundItem) {
  //       setInitialItem(foundItem);
  //       setInputValue(foundItem.name); // Optionally set the name in parent state
  //     }
  //   }
  // }, [value_data, loaction_, setInputValue]);

  const handleOnSearch = (string: any, results: any) => {
    console.log(results, "results", string);
    setInputValue(string);
  };

  const handleOnHover = (result: any) => {
    console.log(result);
  };

  const handleOnSelect = (item: any) => {
    console.log(item, "setFieldValue");
    setFieldValue("suburb", item?.id);
    setSerchvalue(item?.name);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };
  console.log(value_data, "value",initialItem);

  const formatResult = (item: any) => {
    console.log(item, "asxabsxbas");
    return (
      <div className="result-wrapper">
        <span className="result-span">id: {item.id}</span>
        <span className="result-span">name: {item.name}</span>
      </div>
    );
  };

  return (
    <ReactSearchAutocomplete
      items={loaction_}
      onSearch={handleOnSearch}
      onHover={handleOnHover}
      onSelect={handleOnSelect}
      onFocus={handleOnFocus}
      onClear={handleOnClear}
      fuseOptions={{ keys: ["id", "name"] }} // Search in the description text as well
      styling={{ zIndex: 3 }} // To display it on top of the search box below
      inputSearchString={serch_value?serch_value:value_data}
    />
  );
}

export default Autocomplete;
