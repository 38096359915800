import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { GENDER } from "../../../Utils/Constants/select_options";
import SelectComponent from "../../../components/ReactSelect/ReactSelect";
import { useQuery } from "react-query";
import {
  getAustState,
  getProfileData,
  getResidancyData,
  getSubrub,
} from "../../../Api/WebsiteApi";
import { ProfileSubmitHandler } from "../ProfileMethods/ProfileSubmitHandlers";
import { api } from "../../../Api/Api";
import DatePicker from "react-datepicker";

const Step1 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const [profileImgPreview, setProfileImgPreview] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: Residancydata }: any = useQuery(
    [`Residancydatadata`],
    () => getResidancyData(),
    {
      keepPreviousData: true,
    }
  );

  const { data: profiledata }: any = useQuery(
    [`profile`],
    () => getProfileData(),
    {
      keepPreviousData: true,
    }
  );

  const [formData, setFormData] = useState({
    profile_type: "",
    dob: null,
    gender: "",
    residency_status: "",
    // address: "",
    profile_img: null,
    profile_img_: null,
    email: "",
    mobile: "",
    last_name: "",
    street_number: "",
    address_line_one: "",
    address_line_two: "",
    first_name: "",
    suburb: "",
    state: "",
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    api
      .get(`/user/user_details`, true)
      .then(([status, response]) => {
        const data = response?.data;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions

        setFormData({
          profile_type: data?.user_details?.profileType?.id || "",
          dob: data?.user_details?.dob || null,
          gender: data?.user_details?.gender?.id?.toString() || "",
          residency_status: data?.user_details?.residencyStatus?.id || "",
          address_line_one: data?.user_details?.addressLineOne || "",
          address_line_two: data?.user_details?.addressLineTwo || "",
          street_number: data?.user_details?.streetNumber || "",
          profile_img: null,
          profile_img_: data?.user_details?.profileImg || null,
          email: data?.email || "",
          mobile: data?.mobile || "",
          first_name: data?.firstName || "",
          last_name: data?.lastName || "",
          suburb: data?.user_details?.suburb?.id || "",
          state: data?.user_details?.state?.id || "",
        });
      })
      .catch((err) => {});
  };
  const { data: state_aust } = useQuery([`state_aust`], () => getAustState(), {
    keepPreviousData: true,
    staleTime: 1000,
  });
  const { data: surub_data, isLoading: subloading } = useQuery(
    [`surub_data`, formData?.state],
    () => (formData?.state ? getSubrub(formData?.state) : ""),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  console.log(formData, "ashdjagshdagsdgsad");
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, profile_img: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImgPreview(reader?.result);
      };
      reader.readAsDataURL(file);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        profile_img: undefined,
      }));
    }
  };

  const handleChange = (e: any, namez?: any) => {
    if (namez === "dob") {
      const formattedDate: any = e; // Format date if necessary
      setFormData({ ...formData, [namez]: formattedDate });
      setErrors((prevErrors: any) => ({ ...prevErrors, [namez]: undefined }));
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const validateForm = () => {
    let newErrors: any = {};

    if (!formData.first_name) newErrors.first_name = "First name is required";
    if (!formData.last_name) newErrors.last_name = "Last name is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.street_number)
      newErrors.street_number = "Street number is required";
    if (!formData.address_line_two)
      newErrors.address_line_two = "Address line 2 is required";
    if (!formData.address_line_one)
      newErrors.address_line_one = "Address line 1 is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.residency_status)
      newErrors.residency_status = "Residency status is required";
    if (!formData.profile_type) newErrors.profile_type = "Service is required";
    if (!formData.suburb) newErrors.suburb = "Suburb is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.dob) {
      newErrors.dob = "Date of birth is required";
    } else {
      const today = new Date();
      const dob = new Date(formData.dob);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      const dayDifference = today.getDate() - dob.getDate();

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      if (age < 18) {
        newErrors.dob = "You must be 18 years or older";
      }
    }

    if (!formData.profile_img && !formData.profile_img_)
      newErrors.profile_img = "Profile picture is required";

    return newErrors;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    ProfileSubmitHandler(formData, setStep, UserListRefetch, setIsSubmitting);
  };

  return (
    <div>
      <style>{`
        .parent {
          width: auto;
          margin: auto;
          padding: 2rem;
          background: #ffffff;
          border-radius: 25px;
          box-shadow: 7px 20px 20px rgb(210, 227, 244);
        }

        .file-upload {
          text-align: center;
          border: 3px dashed rgb(210, 227, 244);
          padding: 1.5rem;
          position: relative;
          cursor: pointer;
        }

        .file-upload p {
          font-size: 0.87rem;
          margin-top: 10px;
          color: #bbcada;
        }

        .file-upload input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
      `}</style>

      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Which service do you offer?</Label>
          <SelectComponent
            options={profiledata}
            value={profiledata?.find(
              (option: any) => option.value === formData.profile_type
            )}
            onChange={(selectedOption: any) => {
              setFormData({ ...formData, profile_type: selectedOption?.value });
              setErrors((prevErrors: any) => ({
                ...prevErrors,
                profile_type: "",
              }));
            }}
            isClearable={true}
            error={errors?.profile_type ? errors.profile_type : ""}
          />
          {errors.profile_type && (
            <div className="text-danger">{errors.profile_type}</div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>First Name</Label>
          <input
            type="text"
            name="first_name"
            className={`form-control ${
              errors.first_name ? "is-invalid" : "is-valid"
            }`}
            value={formData.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <div className="text-danger">{errors.first_name}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <input
            type="text"
            name="last_name"
            className={`form-control ${
              errors.last_name ? "is-invalid" : "is-valid"
            }`}
            value={formData.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <div className="text-danger">{errors.last_name}</div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>Mobile</Label>
          <input
            type="text"
            name="mobile"
            className={`form-control ${
              errors.mobile ? "is-invalid" : "is-valid"
            }`}
            value={formData.mobile}
            onChange={handleChange}
          />
          {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
        </FormGroup>

        <FormGroup>
          <Label>Email</Label>
          <input
            type="text"
            name="email"
            className={`form-control ${
              errors.email ? "is-invalid" : "is-valid"
            }`}
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </FormGroup>
        <Card>
          <CardBody>
            <FormGroup>
              <Label>Address</Label>

              <hr />
              <FormGroup>
                <Label>Street Number</Label>
                <input
                  type="text"
                  name="street_number"
                  className={`form-control ${
                    errors.street_number ? "is-invalid" : "is-valid"
                  }`}
                  value={formData?.street_number}
                  onChange={handleChange}
                  // style={{ height: "150px" }}
                />
                {errors.street_number && (
                  <div className="text-danger">{errors.street_number}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Address Line - 1</Label>
                <input
                  type="text"
                  name="address_line_one"
                  className={`form-control ${
                    errors.address_line_one ? "is-invalid" : "is-valid"
                  }`}
                  value={formData?.address_line_one}
                  onChange={handleChange}
                  // style={{ height: "150px" }}
                />
                {errors.address_line_one && (
                  <div className="text-danger">{errors.address_line_one}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Address Line - 2</Label>
                <input
                  type="text"
                  name="address_line_two"
                  className={`form-control ${
                    errors.address_line_two ? "is-invalid" : "is-valid"
                  }`}
                  value={formData?.address_line_two}
                  onChange={handleChange}
                  // style={{ height: "150px" }}
                />
                {errors.address_line_two && (
                  <div className="text-danger">{errors.address_line_two}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>State</Label>
                <SelectComponent
                  loading={subloading}
                  options={state_aust}
                  value={state_aust?.find(
                    (option: any) => option.value === formData.state
                  )}
                  onChange={(selectedOption: any) => {
                    setFormData({
                      ...formData,
                      state: selectedOption?.value,
                    });
                    setErrors((prevErrors: any) => ({
                      ...prevErrors,
                      state: "",
                    }));
                  }}
                  isClearable={true}
                  error={errors?.state ? errors.state : ""}

                  // isMulti={true}
                />
                {errors.state && (
                  <div className="text-danger">{errors.state}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Suburb</Label>
                <SelectComponent
                  loading={subloading}
                  options={surub_data?surub_data:[]}
                  value={
                    surub_data
                      ? surub_data?.find(
                          (option: any) => option.value === formData.suburb
                        )
                      : ""
                  }
                  onChange={(selectedOption: any) => {
                    setFormData({
                      ...formData,
                      suburb: selectedOption?.value,
                    });
                    setErrors((prevErrors: any) => ({
                      ...prevErrors,
                      suburb: "",
                    }));
                  }}
                  isClearable={true}
                  error={errors?.suburb ? errors.suburb : ""}

                  // isMulti={true}
                />
                {errors.suburb && (
                  <div className="text-danger">{errors.suburb}</div>
                )}
              </FormGroup>
            </FormGroup>
          </CardBody>
        </Card>

        <FormGroup>
          <Label>Gender</Label>
          <Row>
            {GENDER?.map((item: any, index: number) => (
              <div className="col-2" key={index}>
                <input
                  type="radio"
                  name="gender"
                  value={item.id}
                  checked={formData.gender === item.id.toString()}
                  onChange={handleChange}
                />
                &nbsp;
                <Label className="form-check-label">{item.name}</Label>
              </div>
            ))}
          </Row>
          {errors.gender && <div className="text-danger">{errors.gender}</div>}
        </FormGroup>

        <FormGroup>
          <Label>What is your residency status?</Label>
          <SelectComponent
            options={Residancydata}
            value={Residancydata?.find(
              (option: any) => option.value === formData.residency_status
            )}
            onChange={(selectedOption: any) => {
              setFormData({
                ...formData,
                residency_status: selectedOption?.value,
              });
              setErrors((prevErrors: any) => ({
                ...prevErrors,
                residency_status: "",
              }));
            }}
            isClearable={true}
            error={errors?.residency_status ? errors.residency_status : ""}
          />
          {errors.residency_status && (
            <div className="text-danger">{errors.residency_status}</div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>Date of Birth</Label>
          <DatePicker
            selected={formData.dob ? new Date(formData.dob) : null}
            onChange={(date: any) => handleChange(date, "dob")}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            placeholderText="DD/MM/YYYY"
            className={`form-control ${errors.dob ? "is-invalid" : "is-valid"}`}
            showIcon
          />
          {errors.dob && <div className="text-danger">{errors.dob}</div>}
        </FormGroup>

        <FormGroup>
          <Label>Profile Picture</Label>
          <div className="file-upload">
            {profileImgPreview || formData.profile_img_ ? (
              <img
                src={profileImgPreview || formData.profile_img_}
                alt="Profile Preview"
                width={150}
                height={150}
              />
            ) : (
              <>
                <p>Click here to upload image</p>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="profileImage"
            />
          </div>
          {errors.profile_img && (
            <div className="text-danger">{errors.profile_img}</div>
          )}
        </FormGroup>
        <div className="text-center d-float-center justify-content-center gap-15">
          <Button
            className="btn btn-brand-1"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting && <Spinner size="sm"></Spinner>}
            Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Step1;
