import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import InputSelectCommon from "../../../components/CommonSelect/InputSelect";
import {
  PAYMENT_MODE,
  SUPPORT_FOR_YOU,
} from "../../../Utils/Constants/select_options";
import { useNavigate } from "react-router-dom";
import {
  addModalInitialValuesHandlerStep3,
  ProfileSubmitHandlerStep3,
} from "../ProfileMethods/ProfileSubmitHandlers";
import { validationSchem3 } from "../ProfileMethods/ProfileValidation";
import { initialValuesStep3 } from "../ProfileMethods/profileInitialValues";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Step2 = ({ setStep, Userdata, ListRefetch, UserLoading_data }: any) => {
  const navigate = useNavigate();
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep3);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep3(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep3);
    }
  }, [Userdata, Userdata?.id]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addModalInitialValues}
        validationSchema={validationSchem3}
        onSubmit={(values, actions) => {
          ProfileSubmitHandlerStep3(
            values,
            actions,
            setStep,
            navigate,
            ListRefetch
          );
          // navigate("/success?profile_completed=1");
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          isSubmitting,
        }) => (
          <>
            {" "}
            {UserLoading_data ? (
              <Skeleton count={5} height="6%" />
            ) : (
              <Form
                className="seeker-registration-form2"
                onSubmit={handleSubmit}
              >
                <div className="text-center mb-3 logo-div01">
                  <h3 className="block-subTitle text-center uss-text-info"></h3>
                </div>

                <FormGroup>
                  <Label>Profile Title</Label>
                  <Field
                    type="text"
                    name="care_request_title"
                    className={`form-control ${`${
                      errors.care_request_title ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.care_request_title}
                  />
                  <ErrorMessage
                    name="care_request_title"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Bio Description</Label>
                  <Field
                    as="textarea"
                    name="summary"
                    className={`form-control ${`${
                      errors.summary ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.summary}
                    // rows={100}
                    style={{
                      height: "150px",
                    }}
                  />
                  <ErrorMessage
                    name="summary"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Are there any health concerns or medical conditions we
                    should be aware of?
                  </Label>
                  <Field
                    as="textarea"
                    name="health_status"
                    className={`form-control ${`${
                      errors.health_status ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.health_status}
                    // rows={100}
                    style={{
                      height: "150px",
                    }}
                  />
                  <ErrorMessage
                    name="health_status"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {/* Are there any health concerns or medical conditions we should be aware of? */}

                {/* <div className="text-center">
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    <span> Next </span> <i className="ri-arrow-right-line"></i>
                  </Button>
                </div> */}
                <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                  <Button
                    className="btn btn-brand-2"
                    type="submit"
                    onClick={() => {
                      // navigate("/create-profile-careseeker/2");
                      setStep(2);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>&nbsp;BACK
                  </Button>
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
export default Step2;
