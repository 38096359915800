import { ErrorMessage, Field, FieldArray, FormikErrors } from "formik";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import SelectComponent from "../../../../../components/ReactSelect/ReactSelect";

export default function AdditionalInformation({
  languages,
  interest,
  care_tasks,
  willing_to_work,
  experience_with_aged_cares,
  experience_with_children,
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
}: any) {
  return (
    <>
      <Row>
        <Col className="col-6">
          <FormGroup>
            <Label for={`languages_spokens`}>Language</Label>

            <SelectComponent
              options={languages} // Pass your options here
              onChange={(selectedOption: any) =>
                setFieldValue("languages_spokens", selectedOption)
              }
              onBlur={() => setFieldTouched("languages_spokens", true)}
              isClearable={true}
              error={
                errors?.languages_spokens && touched?.languages_spokens
                  ? errors.languages_spokens
                  : ""
              }
              value={values?.languages_spokens}
              isMulti={true}
            />
            <ErrorMessage
              name={`languages_spokens`}
              component="div"
              className="text-danger"
            />
          </FormGroup>
        </Col>

        <Col className="col-6">
          <FormGroup>
            <Label for={`interests`}>Interest</Label>

            <SelectComponent
              options={interest} // Pass your options here
              onChange={(selectedOption: any) =>
                setFieldValue("interests", selectedOption)
              }
              onBlur={() => setFieldTouched("interests", true)}
              isClearable={true}
              error={
                errors?.interests && touched?.interests ? errors.interests : ""
              }
              isMulti={true}
              value={values?.interests}
            />
            <ErrorMessage
              name={`interests`}
              component="div"
              className="text-danger"
            />
          </FormGroup>
        </Col>

        <Col className="col-6">
          <FormGroup>
            <Label for={`care_tasks`}>Care Task Name</Label>

            <SelectComponent
              options={care_tasks} // Pass your options here
              onChange={(selectedOption: any) =>
                setFieldValue("care_tasks", [selectedOption])
              }
              onBlur={() => setFieldTouched("care_tasks", true)}
              isClearable={true}
              error={
                errors?.care_tasks && touched?.care_tasks
                  ? errors.care_tasks
                  : ""
              }
              value={values?.care_tasks}
              // isMulti={true}
            />
            <ErrorMessage
              name={`care_tasks`}
              component="div"
              className="text-danger"
            />
          </FormGroup>
        </Col>

        <Col className="col-6">
          <FormGroup>
            <Label for={`willing_to_work`}>Willing to Work</Label>

            <SelectComponent
              options={willing_to_work} // Pass your options here
              onChange={(selectedOption: any) =>
                setFieldValue("willing_to_work", selectedOption)
              }
              value={values?.willing_to_work}
              onBlur={() => setFieldTouched("willing_to_work", true)}
              isClearable={true}
              error={
                errors?.willing_to_work && touched?.willing_to_work
                  ? errors.willing_to_work
                  : ""
              }
              isMulti={true}
            />
            <ErrorMessage
              name={`willing_to_work`}
              component="div"
              className="text-danger"
            />
          </FormGroup>
        </Col>

        <Col className="col-6">
          <FormGroup>
            <Label for={`experience_with_aged_cares`}>
              Aged Care Experience
            </Label>

            <SelectComponent
              options={experience_with_aged_cares} // Pass your options here
              value={values?.experience_with_aged_cares}
              onChange={(selectedOption: any) =>
                setFieldValue("experience_with_aged_cares", selectedOption)
              }
              onBlur={() => setFieldTouched("experience_with_aged_cares", true)}
              isClearable={true}
              error={
                errors?.experience_with_aged_cares &&
                touched?.experience_with_aged_cares
                  ? errors.experience_with_aged_cares
                  : ""
              }
              isMulti={true}
            />
            <ErrorMessage
              name={`experience_with_aged_cares`}
              component="div"
              className="text-danger"
            />
          </FormGroup>
        </Col>

        <Col className="col-6">
          <FormGroup>
            <Label htmlFor={`experience_with_children`}>
            Childcare Experience
            </Label>

            <SelectComponent
              options={experience_with_children} // Pass your options here
              value={values?.experience_with_children}
              onChange={(selectedOption: any) =>
                setFieldValue("experience_with_children", selectedOption)
              }
              onBlur={() => setFieldTouched("experience_with_children", true)}
              isClearable={true}
              error={
                errors?.experience_with_children &&
                touched?.experience_with_children
                  ? errors.experience_with_children
                  : ""
              }
              isMulti={true}
            />
            <ErrorMessage
              name={`experience_with_children`}
              component="div"
              className="text-danger"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}
