import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import ChatRoom from "./Chat";

export default function Overview({
  data_path,
  totalExperience,
  caregiver,
  setTab,
}: any) {
  const [openmodal, setOpenModal] = useState(false);
  return (
    <>
      {" "}
      <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-lg-30 find-giver-left-aside">
        <div className="sidebar-border">
          <h5 className="f-18">Overview</h5>
          <div className="sidebar-list-job">
            <ul>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-briefcase-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Experience</span>
                  <strong className="small-heading">
                  {totalExperience?.months === 0
                              ? totalExperience?.years?totalExperience?.years+" Yrs":"1 Month "
                              : totalExperience?.months + " Months "}
                  </strong>
                </div>
              </li>
              {/* <li>
              <div className="sidebar-icon-item">
                <i className="ri-money-dollar-circle-line"></i>
              </div>
              <div className="sidebar-text-info">
                <span className="text-description">
                  Expected Salary
                </span>
                <strong className="small-heading">$26k - $30k</strong>
              </div>
            </li> */}
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-speak-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Language</span>
                  <div className="d-float-center">
                    {data_path?.languages_spokens?.map(
                      (item: any, index: any) => {
                        return (
                          <>
                            <strong className="small-heading">
                              {item?.language?.name}
                              {index < data_path?.languages_spokens?.length - 1 &&
                                ", "}
                            </strong>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </li>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-book-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Education Level</span>
                  {data_path?.qualifications?.map((item: any, index: any) => {
                    return (
                      <>
                        <strong className="small-heading">
                          {item?.courseName}
                          {index < data_path?.qualifications?.length - 1 &&
                            ", "}
                        </strong>
                      </>
                    );
                  })}
                </div>
              </li>
            </ul>
          </div>
          <div className="sidebar-list-job">
            <ul className="ul-disc font-sm">
              {data_path?.qualifications?.map((item: any, index: any) => {
                return (
                  <>
                    <li>
                      {item?.courseName}-{item?.institutionName},
                      <p>
                        {moment(item?.startDate).format("YYYY")}-
                        {moment(item?.endDate).format("YYYY")}
                      </p>
                      <p>{item?.description}</p>
                    </li>
                  </>
                );
              })}
            </ul>
            <div className="mt-30">
              {/* <ul>
                <li>Phone : {caregiver?.mobile}</li>
                <li>Email : {caregiver?.email}</li>
              </ul> */}
              <Button
                className="btn btn-send-message mt-0 find-giver-chat-btn"
                onClick={() => setOpenModal(true)}
              >
                Send Message
              </Button>
            </div>
          </div>
        </div>
        <div className="sidebar-border-bg bg-right single-giver-ads-section">
          <span className="text-grey">WE ARE</span>
          <span className="text-hiring">HIRING</span>
          <p className="font-xxs color-text-paragraph mt-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae
            architecto
          </p>
          <div className="mt-15">
            <a className="btn btn-paragraph-2" href="#">
              Know More
            </a>
          </div>
        </div>
      </div>
      {openmodal && <ChatRoom setOpenModal={setOpenModal} />}
    </>
  );
}
