import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { Field, FieldArray, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";

export default function AddExperience({
  values,
  errors,
  touched,
  setFieldValue,
}: any) {
  // State to manage which accordion item is open
  const [openIndex, setOpenIndex] = useState<number>(0); // Open the first item initially

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index); // Toggle open/closed
  };
  const handleChange = (e: any, namez?: any) => {
    setFieldValue(namez, e);
  };

  return (
    <FieldArray name="work_experiences">
      {({ push, remove }) => (
        <div className="wrk-exp mt-3">
          <div className="d-float-center justify-content-between">
            <h4>WORK EXPERIENCE</h4>
            <Button
              type="button"
              onClick={() =>
                push({
                  company_name: "",
                  designation: "",
                  start_date: "",
                  end_date: "",
                  is_current_job: false,
                  description: "",
                })
              }
              style={{ backgroundColor: "#6ecace" }}
            >
              <i className="ri-add-line" aria-hidden="true"></i> Add Experience
            </Button>
          </div>
          <Accordion open={""} toggle={() => {}}>
            {values?.work_experiences.map((experience: any, index: any) => (
              <AccordionItem className="mt-3">
                <AccordionHeader
                  targetId={index.toString()}
                  onClick={() => toggleAccordion(index)}
                  className="mt-0"
                >
                  <Row className="d-float-center justify-content-between">
                    <Col className="col-12">
                      Experience {index + 1}
                      {/* <Button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          remove(index);
                        }}
                        color="danger"
                        className="ml-2"
                      > */}
                      {/* </Button> */}
                    </Col>
                  </Row>
                </AccordionHeader>
                <AccordionBody
                  style={{ display: openIndex === index ? "block" : "none" }}
                  accordionId={index?.toString()}
                >
                  <Row>
                    <Col className="col-12 mt-4">
                      <div className="d-float-center justify-content-end text-danger">
                        {/* <FormGroup className="form-mb-0">
                          <Field
                            type="checkbox"
                            name={`work_experiences.${index}.is_current_job`}
                            className="form-check-input"
                          />
                          <Label
                            className="form-mb-0"
                            for={`work_experiences.${index}.is_current_job`}
                          >
                            &nbsp;Is Current Job
                          </Label>
                        </FormGroup>{" "} */}
                        {index !== 0 && (
                          <>
                            <i
                              className="ri-delete-bin-6-line"
                              onClick={(e) => {
                                e.stopPropagation();
                                remove(index);
                              }}
                            ></i>
                            &nbsp;Delete Row
                          </>
                        )}
                      </div>
                      <hr className="my-2"></hr>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`work_experiences.${index}.company_name`}>
                          Company Name
                        </Label>
                        <Field
                          type="text"
                          name={`work_experiences.${index}.company_name`}
                          className={`form-control ${
                            (errors?.work_experiences?.[index] as any)
                              ?.company_name &&
                            (touched?.work_experiences?.[index] as any)
                              ?.company_name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`work_experiences.${index}.company_name`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`work_experiences.${index}.designation`}>
                          Designation
                        </Label>
                        <Field
                          type="text"
                          name={`work_experiences.${index}.designation`}
                          className={`form-control ${
                            (errors?.work_experiences?.[index] as any)
                              ?.designation &&
                            (touched?.work_experiences?.[index] as any)
                              ?.designation
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`work_experiences.${index}.designation`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`work_experiences.${index}.start_date`}>
                          Start Date
                        </Label>
                        <DatePicker
                          selected={
                            values?.work_experiences?.[index]?.start_date
                              ? new Date(
                                  values?.work_experiences?.[index]?.start_date
                                )
                              : null
                          }
                          name={`work_experiences.${index}.start_date`}
                          showIcon
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          onChange={(e: any) =>
                            handleChange(
                              e,
                              `work_experiences.${index}.start_date`
                            )
                          }
                          className={`form-control ${
                            (errors?.work_experiences?.[index] as any)
                              ?.start_date &&
                            (touched?.work_experiences?.[index] as any)
                              ?.start_date
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`work_experiences.${index}.start_date`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`work_experiences.${index}.end_date`}>
                          End Date
                        </Label>
                        <DatePicker
                          selected={
                            values?.work_experiences?.[index]?.end_date
                              ? new Date(
                                  values?.work_experiences?.[index]?.end_date
                                )
                              : null
                          }
                          name={`work_experiences.${index}.end_date`}
                          showIcon
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          onChange={(e: any) =>
                            handleChange(e, `work_experiences.${index}.end_date`)
                          }
                          className={`form-control ${
                            (errors?.work_experiences?.[index] as any)
                              ?.end_date &&
                            (touched?.work_experiences?.[index] as any)
                              ?.end_date
                              ? "is-invalid"
                              : ""
                          }`}
                          // disabled={
                          //   values.work_experiences[index].is_current_job
                          // }
                        />
                        <ErrorMessage
                          name={`work_experiences.${index}.end_date`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-12">
                      <FormGroup>
                        <Label for={`work_experiences.${index}.description`}>
                          Description
                        </Label>
                        <Field
                          as="textarea"
                          name={`work_experiences.${index}.description`}
                          style={{ height: "100px" }}
                          className={`form-control ${
                            (errors?.work_experiences?.[index] as any)
                              ?.description &&
                            (touched?.work_experiences?.[index] as any)
                              ?.description
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`work_experiences.${index}.description`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      )}
    </FieldArray>
  );
}
