import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useState } from "react";
import constants from "../../Utils/Constants/Constants";

const SucccessPageCare = () => {
  const currentUrl = window.location.href;
  const [completed, setCompleted] = useState(false);

  // Step 2: Create a URL object
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  const id: any = params.get("profile_completed");
  const navigate = useNavigate();
  const [isOpen, setIsopen] = useState(true);
  const go_Home = () => {
    if (id === "1") {
      setIsopen(false);
      navigate("/register");
    } else {
      setIsopen(false);
      navigate("/create-profile-careseeker");
    }
  };
  return (
    <>
      <style>
        {`
          .download-app-popup .head-section .app-link-head {
            padding: 0 215px;
          }    
            .download-app-popup .app-link-content {
                padding: 0 270px;
            }      
            b { font-weight: 600; }
        `}
      </style>
      <Modal
        className="download-app-popup"
        isOpen={isOpen}
        scrollable={true}
        centered={false}
        fullscreen={true}
        dialogClassName="modal-lg modal-xl modal-fullscreen profile-created-modal01"
        toggle={() => setIsopen(false)}
      >
        <ModalHeader className="border-0" toggle={() => go_Home()}>
          {/* <span className="title-head">{title}</span> */}
        </ModalHeader>
        <ModalBody className="">
          <main className="main">
            <section className="section-box mt-0">
              <div className="container">
                <div className="row flex-row-reverse">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="content-page">
                      <div className="row">
                        {id === "1" ? (
                          <div className="download-app-popup">
                            <div className="row andriod-ios-app-download">
                              <div className="col-md-12">
                                <div className="app-link-content text-center">
                                  <div className="text-center mb-0 logo-div01">
                                    <img
                                      src={`${constants.IMAGE_PATH.IMAG_PATH}template/success.gif`}
                                      alt=""
                                      className="justify-content-center"
                                      style={{
                                        width: "150px",
                                      }}
                                    />
                                  </div>
                                  <h4 className="sub-head mb-3">
                                    Congratulations!
                                    <br />
                                    Your profile has now been created with USS.
                                  </h4>
                                  <p className="sub-text1">
                                    Following successful verification, you will
                                    receive an email notification confirming the
                                    activation of your profile.
                                  </p>
                                  <p className="sub-text1">
                                    This process typically takes 2-3 business
                                    days.
                                  </p>
                                  <p className="sub-text1">
                                    Should you have any questions or concerns,
                                    please do not hesitate to contact us at
                                    &nbsp;
                                    <a href="tel:1300 052 000">
                                      <b>1300 052 000</b>
                                    </a>
                                    .
                                  </p>
                                </div>
                                <hr className="app-border-block"></hr>
                              </div>
                              <div className="col-md-12">
                                <div className="head-section d-float-center justify-content-center">
                                  <h3 className="app-link-head">
                                    <span className="text1">Download</span>
                                    &nbsp;
                                    <span className="logo">
                                      <img
                                        src={`${constants.IMAGE_PATH.IMAG_PATH}template/fav.png`}
                                        alt=""
                                      />
                                    </span>
                                    <span className="site-name">USS</span>&nbsp;
                                    <span className="text2">
                                      App to Get Started.
                                    </span>
                                  </h3>
                                </div>
                              </div>
                              <div className="col-md-6 col-6 app-scanner-box app-left">
                                <div className="qr-box">
                                  <a
                                    href="https://www.apple.com/in/app-store/"
                                    target="-blank"
                                  >
                                    <img
                                      className="qr-img"
                                      srcSet={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                                      alt="App Store"
                                    />
                                    <p className="get-app-text">Get iOS App</p>
                                  </a>
                                </div>
                              </div>
                              <div className="col-md-6 col-6 app-scanner-box app-right">
                                <div className="qr-box">
                                  <a
                                    href="https://play.google.com/store/games?device=windows"
                                    target="-blank"
                                  >
                                    <img
                                      className="qr-img"
                                      srcSet={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                                      alt="Play Store"
                                    />
                                    <p className="get-app-text">
                                      Get Android App
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="d-float-center h-100 w-100 justify-content-center account-verified careseeker mt-80">
                            <Card className="p-50">
                              <div className="text-center mb-3 logo-div01 p-50 py-0">
                                <img
                                  src="https://i.pinimg.com/originals/32/b6/f2/32b6f2aeeb2d21c5a29382721cdc67f7.gif"
                                  alt=""
                                  className="justify-content-center"
                                  style={{
                                    width: "100px",
                                  }}
                                />
                                <h2 className="block-subTitle text-center uss-text-info mb-4">
                                  <span className="uss-text-primary">
                                    ACCOUNT VERIFIED
                                  </span>
                                </h2>
                                <span>
                                  Your account has been verified successfully,
                                  <br />
                                  now create your and find your ideal support
                                  working you'r looking for!
                                </span>
                                <br />
                                <Button
                                  className="btn uss-bg-primary mt-40"
                                  onClick={() =>
                                    navigate("/create-profile-careseeker")
                                  }
                                >
                                  Create Profile
                                </Button>
                              </div>
                            </Card>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </ModalBody>
      </Modal>
    </>
  );
};
export default SucccessPageCare;
