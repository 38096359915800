export default function ShortBio({
  view_dataExp,
  data_path,
  view_data,
  view_dataEdu,
  setopenmoadl,
}: any) {
  return (
    <>
      {" "}
      <div className="tab-pane fade show active">
        {" "}
        <h4>About Me</h4>
        <p>{data_path?.aboutYourself}</p>
        <div className="row">
          {/* super */}
          {view_data(
            "Experience With Aged Care",
            data_path?.experience_with_aged_cares,
            "experienceWithAgedCare"
          )}
          {view_data(
            "Experience With Children",
            data_path?.experience_with_children,
            "experienceWithChildren"
          )}
          {/* {view_data(
            "Language Spoken",
            data_path?.languages_spokens,
            "language"
          )} */}
          {view_data(
            "Willing To Work",
            data_path?.willing_to_work,
            "willingToWork"
          )}
          {view_data("Interests", data_path?.interests, "interest")}
          {view_data("Service Type", data_path?.service_provides, "serviceProvides")}

          <div className="col-lg-3 col-md-4 book-now-btn-sec01">
            <p className="d-float-center h-100 justify-content-end">
              <a
                className="btn btn-default"
                href="#"
                onClick={() => setopenmoadl(true)}
              >
                Book Now
              </a>
            </p>
          </div>
        </div>
        <hr />
        <h4>Work Experience</h4>
        {view_dataExp(data_path?.work_experiences)}
        <h4>Education</h4>
        {view_dataEdu(data_path?.qualifications)}
      </div>
    </>
  );
}
