/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  goToPreviousPage,
  goToPage,
  goToNextPage,
  totalPages,
  currentPage,
  type = 0,
}: any) => {
  console.log(totalPages, "totalPages");
  return (
    <>
      <style>
        {`
        .disable_pointer_events {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.4;
        }
        `}
      </style>
      <div
        className={`${
          type === 1 ? "paginations text-center mb-0" : "paginations m-0"
        }`}
      >
        <ul className="pager">
          <li className="disabled">
            <a
              href="#"
              className={`pager-prev ${
                currentPage <= 1 ? "disable_pointer_events" : ""
              }`}
              style={{ height: "30px", borderRadius: "999px", width: "30px" }}
              onClick={goToPreviousPage}
            ></a>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} style={{ cursor: "pointer" }}>
              <a
                className={`pager-number ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                href="#"
                onClick={() => goToPage(index + 1)}
              >
                {index + 1}
              </a>
            </li>
          ))}
          <li>
            <a
              className={`pager-next ${
                totalPages <= 1 ? "disable_pointer_events" : ""
              }`}
              onClick={goToNextPage}
              style={{ height: "30px", borderRadius: "999px", width: "30px" }}
            ></a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;
